import React, { Fragment, useEffect, memo } from 'react';
import styled from 'styled-components/macro';
import PbiFilterSystems from './PbiFilterSystemsContainer';
import PbiFilterTimeframe from './PbiFilterTimeframeContainer';
import { PbiFilterProps } from './PbiFilterContainer';
import CalendarIcon from '../_common/Icons/CalendarIcon';
import FilterCalenderModal from '../FiltersCalendarModal/FilterCalendarModalContainer';
import { getQueryParams, updateUriQueryAndPushHistory } from '../../helpers/navigation';
import Desktop from '../_common/Desktop';
import { isValid } from 'date-fns';
import PbiFilterSystemGroup from './PbiFilterSystemGroupContainer';
import { UserSystemGroupStrings } from 'store/storeTypes';
import {
  apiRefreshInterval,
  getDayForDateMobile,
  getMonthForDate,
  getQuarterForDate,
  getWeekForDate,
  getYearForDate,
} from 'helpers/date';
import { REPORTS_CONFIG, SubReportTypes, TimeFrame } from 'models/ReportData';
import { ReactComponent as BackArrow } from '../../assets/icons/BackArrow.svg';
import { ReactComponent as ServiceTicketIcon } from '../../assets/icons/ServiceTicketIcon.svg';
import { makeTreeData } from 'components/_common/TreeSelect/treeHelpers';
import { KonnectAnalytics } from 'KonnectAnalytics/konnectAnalytics';
const FilterContainer = styled.div`
  display: flex;
  position: relative;
  background-color: #fff;
  border-bottom: 1px solid #e6eaee;
  height: 49px;
  max-height: 49px;
  align-items: center;
  padding-top: 2px;
  user-select: none;
  &.disabled::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
  }
  @media (max-width: 750px) {
    height: 65px;
    max-height: 65px;
  }
`;

const FilterSubContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  height: 49px;
  max-height: 49px;
  min-height: 49px;
  box-sizing: border-box;
  &.drill-page {
    margin-right: 81px;
  }
`;

const MobileView = styled.div`
  display: none;
  @media (max-width: 750px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 65px;
    max-height: 65px;
  }
`;

const MobileIconWrapper = styled.div`
  margin-right: 20px;
  padding-left: 5%;
`;

const MobileDateWrapper = styled.div`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #354052;
`;
const ServiceTicketResponsiveContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  min-width: 200px;
  align-items: center;
  min-height: 70px;
  box-sizing: border-box;
`;
const BackArrowWrapper = styled.div`
  min-width: 50px;
  min-height: 40px;
  max-height: 55px;
  border-right: 1px solid #c5d0de;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #ececec;
  }
`;
const ServiceTicketHeaderWrapper = styled.div`
  min-width: 150px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.5625px;
  color: #354052;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 20px;
`;
const ServiceTicketIconWrapper = styled.div``;
const ServiceTicketTextnWrapper = styled.div`
  padding-left: 10px;
`;
const PbiFilter = (props: PbiFilterProps) => {
  const {
    filterDataLoaded,
    selectedSystemGroup,
    filterStateLoaded,
    filterState,
    filterData,
    location,
    stateInitialized,
    userSettings,
    openCalendarModal,
    setInitFilterState,
    getReportsFilterDates,
    getReportsFilterSites,
    getReportsCurrentState,
    setFilterFromUriQuery,
    applyMobileFilters,
    getDefaultFilterQueries,
    setTimeframe,
    setFilterSystemsState,
    getEmptyState,

    day,
    activeReport,
    history,
    isSiteExtensionIsLoaded,
    screenWidth,
    isTimeFrameWasChangedFromJobAnalysisPage,
    isPbiFilterHidden,
  } = props;
  const {
    selectedDate,
    timeframe,
    selectedSiteIdsStringified,
    selectedModelIdsStringified,
    selectedSystemSerialsStringified,
  } = filterState;

  const { search } = location;
  const filterDataAndStateLoaded = filterDataLoaded && filterStateLoaded;
  const urlParams = getQueryParams(search);

  // Get filters data from API
  useEffect(() => {
    if (!filterDataLoaded) {
      getReportsFilterDates();
      getReportsFilterSites();
    }
    if (isSiteExtensionIsLoaded) {
      getEmptyState();
    }
  }, [filterDataLoaded, getReportsFilterDates, getReportsFilterSites, isSiteExtensionIsLoaded]);

  // Get filters state from API
  useEffect(() => {
    if (!filterStateLoaded) getReportsCurrentState();
  }, [filterStateLoaded, getReportsCurrentState]);

  // Get filters refresh from API
  useEffect(() => {
    // if (!FILTER_HIDE) {
    return apiRefreshInterval({ day, timeframe }, () => {
      getReportsCurrentState();
    });
    // }
  }, []);
  // Set filter init state
  useEffect(() => {
    if (filterDataAndStateLoaded && !stateInitialized) {
      setInitFilterState();

      const allSystemsSelected = makeTreeData({
        rootElements: filterData[selectedSystemGroup].sites,
        parentElements: filterData[selectedSystemGroup].models.map(m => ({
          id: m.id,
          name: m.name,
          rootId: m.siteId,
        })),
        childElements: filterData[selectedSystemGroup].systems.map(s => ({
          id: s.name,
          name: s.name,
          rootId: s.siteId,
          parentId: s.modelId,
        })),
        rootSelectedIds: filterData[selectedSystemGroup].sites.map(site => site.id),
        parentSelectedIds: filterData[selectedSystemGroup].models.map(model => model.id),
        childSelectedIds: filterData[selectedSystemGroup].systems.map(system => system.name),

        enableLowLevelSelect: true,
      });
      setFilterSystemsState(allSystemsSelected);
    }
  }, [filterDataAndStateLoaded, setInitFilterState, stateInitialized]);

  // URL query => filter state
  useEffect(() => {
    if (stateInitialized && search) {
      setFilterFromUriQuery({
        timeframe: urlParams.get('timeframe') as TimeFrame,
        selectedSystemGroup: urlParams.get('selectedSystemGroup') as UserSystemGroupStrings,
        selectedDate: urlParams.get('day'),
        selectedSiteIds: urlParams.get('sites'),
        selectedModelIds: urlParams.get('models'),
        selectedSystemSerials: urlParams.get('systems'),
      });
    }
  }, [stateInitialized, search]);
  // Filter state => URL query
  useEffect(() => {
    if (!filterDataAndStateLoaded || !location.pathname.match(/\/reports\//)) {
      return;
    }
    if (activeReport && activeReport === 'jobs' && !isTimeFrameWasChangedFromJobAnalysisPage) {
      // this if is needed after job analysis supprot day filter;
      //functionality: if user doesn't select timeframe on job analysis page - by default there will be week filter
      timeframe !== 'week' &&
        timeframe !== 'custom' &&
        setTimeframe(
          REPORTS_CONFIG[selectedSystemGroup]![activeReport].defaultTimeFrame,
          filterData[selectedSystemGroup].startDate
        );
    } else if (
      activeReport &&
      (timeframe === undefined ||
        !REPORTS_CONFIG[selectedSystemGroup][activeReport].timeFrames.includes(timeframe))
    ) {
      setTimeframe(
        REPORTS_CONFIG[selectedSystemGroup]![activeReport].defaultTimeFrame,
        filterData[selectedSystemGroup].startDate
      );
    } else {
      updateUriQueryAndPushHistory(
        {
          timeframe,
          day: selectedDate && isValid(selectedDate) ? selectedDate.toISOString() : undefined,
          selectedSystemGroup,
        },
        activeReport
      );
    }
  }, [
    timeframe,
    selectedDate,
    selectedSystemGroup,
    selectedSiteIdsStringified,
    selectedModelIdsStringified,
    selectedSystemSerialsStringified,
    filterDataAndStateLoaded,
    location.pathname,
  ]);
  const onOpenCalendarModal = () => {
    getDefaultFilterQueries({
      timeframe: urlParams.get('timeframe') as TimeFrame,
      selectedSystemGroup: urlParams.get('selectedSystemGroup') as UserSystemGroupStrings,
      selectedDate: urlParams.get('day'),
      selectedSiteIds: urlParams.get('sites'),
      selectedModelIds: urlParams.get('models'),
      selectedSystemSerials: urlParams.get('systems'),
    });
    applyMobileFilters(false);
    KonnectAnalytics.toggleDatePickerCalendar();
    openCalendarModal();
  };

  const renderDateTitle = () => {
    return (
      selectedDate && (
        <Fragment>
          {timeframe === 'day' && getDayForDateMobile(selectedDate).title}
          {timeframe === 'week' && getWeekForDate(selectedDate).title}
          {timeframe === 'month' && getMonthForDate(selectedDate).title}
          {timeframe === 'quarter' && getQuarterForDate(selectedDate).title}
          {timeframe === 'year' && getYearForDate(selectedDate).title}
        </Fragment>
      )
    );
  };
  if (isPbiFilterHidden) {
    return null;
  }
  return (
    <FilterContainer>
      {stateInitialized && (
        <Fragment>
          {activeReport !== SubReportTypes.ServiceTicket ? (
            <>
              {screenWidth <= 750 && (
                <MobileView>
                  <Fragment>
                    {activeReport === 'systemPage' ? (
                      <BackArrowWrapper onClick={() => history.goBack()}>
                        <BackArrow />
                      </BackArrowWrapper>
                    ) : (
                      <PbiFilterSystems />
                    )}
                    <div style={{ display: 'flex' }}>
                      <MobileIconWrapper>
                        <CalendarIcon onClick={onOpenCalendarModal} />
                      </MobileIconWrapper>
                      <MobileDateWrapper onClick={onOpenCalendarModal}>
                        {renderDateTitle()}
                      </MobileDateWrapper>
                      {/* pop up calendar for mobile is here  should be replaced after refactoring */}
                      <FilterCalenderModal isMobile={true} />
                    </div>
                    {userSettings.userSystemGroup?.length >= 2 &&
                    !location.pathname.includes(SubReportTypes.SystemPage) &&
                    !location.pathname.includes(SubReportTypes.MachineConfig) ? (
                      <div style={{ display: 'flex', marginRight: '5px' }}>
                        <PbiFilterSystemGroup />
                      </div>
                    ) : (
                      <>
                        <div style={{ width: '100px' }}></div>
                      </>
                    )}
                  </Fragment>
                </MobileView>
              )}
              {screenWidth > 750 && (
                <Desktop>
                  <FilterSubContainer>
                    <PbiFilterSystems />
                    <PbiFilterTimeframe />
                  </FilterSubContainer>
                </Desktop>
              )}
            </>
          ) : (
            <>
              <ServiceTicketResponsiveContainer>
                <BackArrowWrapper onClick={() => history.goBack()}>
                  <BackArrow />
                </BackArrowWrapper>
                <ServiceTicketHeaderWrapper>
                  <ServiceTicketIconWrapper>
                    <ServiceTicketIcon />
                  </ServiceTicketIconWrapper>
                  <ServiceTicketTextnWrapper>Service Ticket </ServiceTicketTextnWrapper>
                </ServiceTicketHeaderWrapper>
              </ServiceTicketResponsiveContainer>
            </>
          )}
        </Fragment>
      )}
    </FilterContainer>
  );
};

export default memo(PbiFilter);
