import _ from 'lodash';
import { createReducer } from 'typesafe-actions';
import { createSelector } from 'reselect';
import {
  jobAnalysisToggleSelectedColorsAC,
  jobAnalysisTotalCardsAC,
  jobAnalysisChartCardsAC,
  jobAnalysisChartsAC,
  downloadInkConsumptionPerJobReportAC,
  isInkConsumptionPerJobValidToDownloadAC,
  jobAnalysisInkConsumptionKPIAC,
  jobAnalysisPrintingTimeKPIAC,
  jobAnalysisTotalImpressionsKPIAC,
  jobAnalysisTotalBottlesLoadedKPIAC,
} from 'actions/jobAnalysisActions';
import {
  getJobSummaryCardsAC,
  selectApolloCard,
  toggleSelectionSizeGroupCards,
} from 'actions/jobSummaryActions';
import { mergeCopyArrays } from 'helpers/conversions';
import {
  inkConsuptionPerJobStatuses,
  JobAnaylsisByType,
  Store,
  UserSystemGroup,
} from 'store/storeTypes';
import { systemGroupSelector } from './ui/systemGroupUiReducer';
import { getMaxKeepAliveRequestAC } from 'actions/overviewActions';
import {
  setActiveDateAC,
  setTimeframeAC,
  toggleAllSystemsSelection,
  toggleFilterModelRowSystemsSelection,
  toggleFilterRowSystemsSelection,
  toggleFilterSerialRowSystemSelection,
  unselectSerialsByModel,
} from 'actions/reportFilterActions';

const INIT_STATE: JobAnaylsisByType = {
  DTG: {
    bottlesLoaded: undefined,
    totalPrintingTime: undefined,
    totalProduction: undefined,
    selectedColors: ['Total'],
    printingTimePerUnit: undefined,
    inkConsumptionPerUnit: undefined,
    mediaWithResponse: undefined,
    inkConsumptionChart: undefined,
    colorConsumptionChart: undefined,
    colorCompareChart: undefined,
    isJobAnalysisChartsLoading: false,
    isJobAnalysisChartsLoaded: false,
    isTotalCardsLoading: false,
    shouldBeUpdatedAfterGroupSelection: false,
    isDownloadInkConsumptionPerJobLoaded: false,
    isDownloadInkConsumptionPerJobLoading: false,
    downloadInkConsumptionPerJobError: '',
    maxKeepAlive: undefined,
  },
  DTF: {
    bottlesLoaded: undefined,
    totalPrintingTime: undefined,
    totalProduction: undefined,
    selectedColors: ['Total'],
    printingTimePerUnit: undefined,
    inkConsumptionPerUnit: undefined,
    mediaWithResponse: undefined,
    inkConsumptionChart: undefined,
    colorConsumptionChart: undefined,
    colorCompareChart: undefined,
    isJobAnalysisChartsLoading: false,
    isJobAnalysisChartsLoaded: false,
    isTotalCardsLoading: false,
    shouldBeUpdatedAfterGroupSelection: false,
    isDownloadInkConsumptionPerJobLoading: false,
    isDownloadInkConsumptionPerJobLoaded: false,
    downloadInkConsumptionPerJobError: '',
    maxKeepAlive: undefined,
  },
  Apollo: {
    bottlesLoaded: undefined,
    totalPrintingTime: undefined,
    totalProduction: undefined,
    selectedColors: ['Total'],
    printingTimePerUnit: undefined,
    inkConsumptionPerUnit: undefined,
    mediaWithResponse: undefined,
    inkConsumptionChart: undefined,
    colorConsumptionChart: undefined,
    colorCompareChart: undefined,
    isJobAnalysisChartsLoading: true,
    isJobAnalysisChartsLoaded: false,
    isTotalCardsLoading: false,
    shouldBeUpdatedAfterGroupSelection: false,
    isDownloadInkConsumptionPerJobLoading: false,
    isDownloadInkConsumptionPerJobLoaded: false,
    downloadInkConsumptionPerJobError: '',
    maxKeepAlive: undefined,
  },
};
const reducer = createReducer<JobAnaylsisByType, any>(INIT_STATE)
  .handleAction(
    [
      setTimeframeAC,
      setActiveDateAC,
      unselectSerialsByModel,
      toggleAllSystemsSelection,
      toggleFilterSerialRowSystemSelection,
      toggleFilterModelRowSystemsSelection,
      toggleFilterRowSystemsSelection,
    ],
    (state, action) => {
      return INIT_STATE;
    }
  )
  .handleAction(selectApolloCard, (state, action) => {
    const { selectedSystemGroup } = action.payload;
    return {
      ...state,
      [selectedSystemGroup]: {
        ...state[selectedSystemGroup],
        printingTimePerUnit: undefined,
        inkConsumptionChart: undefined,
        colorCompareChart: undefined,
        inkConsumptionPerUnit: undefined,
        colorConsumptionChart: undefined,
        isJobAnalysisChartsLoading: true,
        isJobAnalysisChartsLoaded: false,
      },
    };
  })

  .handleAction(jobAnalysisChartCardsAC.request, (state, action) => {
    const systemGroup = action.payload.selectedSystemGroup;
    return {
      ...state,
      [systemGroup]: {
        ...state[systemGroup],
        printingTimePerUnit: undefined,
        inkConsumptionPerUnit: undefined,
        selectedColors: state[systemGroup].selectedColors,
      },
    };
  })
  .handleAction(jobAnalysisChartCardsAC.success, (state, action) => {
    return _.mergeWith(_.cloneDeep(state), { ...action.payload }, mergeCopyArrays);
  })

  .handleAction(jobAnalysisToggleSelectedColorsAC, (state, action) => {
    return _.mergeWith(_.cloneDeep(state), { ...action.payload }, mergeCopyArrays);
  })

  .handleAction(jobAnalysisTotalCardsAC.request, (state, action) => {
    return {
      ...state,
      DTG: {
        ...state.DTG,
        selectedColors: state.DTG.selectedColors,
        isTotalCardsLoading: true,
      },
      DTF: {
        ...state.DTF,
        selectedColors: state.DTF.selectedColors,
        isTotalCardsLoading: true,
      },
      Apollo: {
        ...state.Apollo,
        selectedColors: state.Apollo.selectedColors,
        isTotalCardsLoading: true,
      },
    };
  })
  .handleAction(jobAnalysisTotalCardsAC.success, (state, action) => {
    const mergedState = _.mergeWith(_.cloneDeep(state), { ...action.payload }, mergeCopyArrays);
    const updatedState = {
      ...mergedState,
      DTF: { ...mergedState.DTF, isTotalCardsLoading: false },
      DTG: { ...mergedState.DTG, isTotalCardsLoading: false },
      Apollo: { ...mergedState.Apollo, isTotalCardsLoading: false },
    };
    return updatedState;
  })

  .handleAction(toggleSelectionSizeGroupCards, (state, action) => {
    return { ...state, shouldBeUpdatedAfterGroupSelection: true };
  })

  .handleAction(isInkConsumptionPerJobValidToDownloadAC.request, (state, action) => {
    const { SystemGroupType: selectedSystemGroup } = action.payload;
    return {
      ...state,
      [selectedSystemGroup]: {
        ...state[selectedSystemGroup],
        isInkConsumptionPerJobValidToDownloadLoading: true,
        isInkConsumptionPerJobValidToDownloadLoaded: false,
      },
    };
  })
  .handleAction(isInkConsumptionPerJobValidToDownloadAC.failure, (state, action) => {
    const { SystemGroupType: selectedSystemGroup } = action.payload;
    return {
      ...state,
      [selectedSystemGroup]: {
        ...state[selectedSystemGroup],
        isInkConsumptionPerJobValidToDownloadLoading: false,
        isInkConsumptionPerJobValidToDownloadLoaded: false,
        isInkConsumptionPerJobValidToDownloadMessage: action.payload.name,
      },
    };
  })
  .handleAction(isInkConsumptionPerJobValidToDownloadAC.success, (state, action) => {
    const { SystemGroupType: selectedSystemGroup, status } = action.payload;
    let message = 'Loading';
    if (status === 204) {
      message = inkConsuptionPerJobStatuses.noData;
    }
    if (status === 205) {
      message = inkConsuptionPerJobStatuses.tooMuchData;
    }
    if (status === 200) {
      message = inkConsuptionPerJobStatuses.validToDownload;
    }

    return {
      ...state,
      [selectedSystemGroup]: {
        ...state[selectedSystemGroup],
        isInkConsumptionPerJobValidToDownloadLoading: false,
        isInkConsumptionPerJobValidToDownloadLoaded: true,
        isInkConsumptionPerJobValidToDownloadMessage: message,
      },
    };
  })

  .handleAction(downloadInkConsumptionPerJobReportAC.request, (state, action) => {
    const { SystemGroupType: selectedSystemGroup } = action.payload;
    return {
      ...state,
      [selectedSystemGroup]: {
        ...state[selectedSystemGroup],
        isDownloadInkConsumptionPerJobLoading: true,
        isDownloadInkConsumptionPerJobLoaded: false,
        downloadInkConsumptionPerJobError: '',
      },
    };
  })
  .handleAction(downloadInkConsumptionPerJobReportAC.success, (state, action) => {
    const { SystemGroupType: selectedSystemGroup, status } = action.payload;
    if (status === 204) {
      return {
        ...state,
        [selectedSystemGroup]: {
          ...state[selectedSystemGroup],
          isDownloadInkConsumptionPerJobLoading: false,
          isDownloadInkConsumptionPerJobLoaded: false,
          downloadInkConsumptionPerJobError: '',
        },
      };
    }
    if (status === 205) {
      return {
        ...state,
        [selectedSystemGroup]: {
          ...state[selectedSystemGroup],
          isDownloadInkConsumptionPerJobLoading: false,
          isDownloadInkConsumptionPerJobLoaded: false,
          downloadInkConsumptionPerJobError: '',
        },
      };
    } else {
      return {
        ...state,
        [selectedSystemGroup]: {
          ...state[selectedSystemGroup],
          isDownloadInkConsumptionPerJobLoading: false,
          isDownloadInkConsumptionPerJobLoaded: true,
          downloadInkConsumptionPerJobError: '',
          isInkConsumptionPerJobValidToDownloadLoading: false,
          isInkConsumptionPerJobValidToDownloadMessage: inkConsuptionPerJobStatuses.validToDownload,
        },
      };
    }
  })

  .handleAction(downloadInkConsumptionPerJobReportAC.failure, (state, action) => {
    const { SystemGroupType: selectedSystemGroup, message } = action.payload;
    return {
      ...state,
      [selectedSystemGroup]: {
        ...state[selectedSystemGroup],
        isDownloadInkConsumptionPerJobLoading: false,
        isDownloadInkConsumptionPerJobLoaded: false,
        downloadInkConsumptionPerJobError: message,
      },
    };
  })

  .handleAction(jobAnalysisChartsAC.request, (state, action) => {
    return {
      ...state,
      DTG: { ...state.DTG, isJobAnalysisChartsLoading: true, isJobAnalysisChartsLoaded: false },
      DTF: { ...state.DTF, isJobAnalysisChartsLoading: true, isJobAnalysisChartsLoaded: false },
      Apollo: {
        ...state.Apollo,
        isJobAnalysisChartsLoading: true,
        isJobAnalysisChartsLoaded: false,
      },
    };
  })
  .handleAction(jobAnalysisChartsAC.success, (state, action) => {
    const systemGroup = Object.keys(action.payload)[0];
    return {
      ...state,
      [systemGroup]: {
        ...state[systemGroup],
        colorCompareChart: action.payload[systemGroup].colorCompareChart,
        inkConsumptionChart: action.payload[systemGroup].inkConsumptionChart,
        colorConsumptionChart: action.payload[systemGroup].colorConsumptionChart,
        isJobAnalysisChartsLoading: false,
        isJobAnalysisChartsLoaded: true,
      },
    };
  })
  .handleAction(jobAnalysisChartsAC.failure, (state, error) => {
    return { ...state, isJobAnalysisChartsLoading: false, isJobAnalysisChartsLoaded: false };
  })

  .handleAction(jobAnalysisInkConsumptionKPIAC.request, (state, action) => {
    if (action.payload.isSilent) {
      return { ...state };
    }
    return {
      ...state,
      [action.payload.SystemGroupType]: {
        colorCompareChart: undefined,
        inkConsumptionChart: undefined,
        colorConsumptionChart: undefined,
        inkConsumptionPerUnit: undefined,
        selectedColors: state[action.payload.SystemGroupType].selectedColors,
        isJobAnalysisChartsLoading: true,
        isJobAnalysisChartsLoaded: false,
      },
    };
  })

  .handleAction(jobAnalysisInkConsumptionKPIAC.success, (state, action) => {
    const systemGroup = Object.keys(action.payload)[0];
    return {
      ...state,
      [systemGroup]: {
        ...state[systemGroup],
        colorCompareChart: action.payload[systemGroup].colorCompareChart,
        inkConsumptionChart: action.payload[systemGroup].inkConsumptionChart,
        colorConsumptionChart: action.payload[systemGroup].colorConsumptionChart,
        inkConsumptionPerUnit: action.payload[systemGroup].inkConsumptionPerUnit,
        isJobAnalysisChartsLoading: false,
        isJobAnalysisChartsLoaded: true,
      },
    };
  })
  .handleAction(jobAnalysisPrintingTimeKPIAC.request, (state, action) => {
    if (action.payload.isSilent) {
      return { ...state };
    }
    return {
      ...state,
      [action.payload.SystemGroupType]: {
        printingTimePerUnit: undefined,
        totalPrintingTime: undefined,
        selectedColors: state[action.payload.SystemGroupType].selectedColors,
      },
    };
  })
  .handleAction(jobAnalysisPrintingTimeKPIAC.success, (state, action) => {
    const systemGroup = action.payload.SystemGroupType;
    const printingTimePerUnit =
      systemGroup === UserSystemGroup.Apollo
        ? action.payload.data.cycleTimePerImpressionResponse
        : action.payload.data.printTimePerUnitResponse;
    const totalPrintingTime =
      systemGroup === UserSystemGroup.Apollo
        ? action.payload.data.totalCycleTimeResponse.totalCycleTimeSeconds
        : action.payload.data.totalPrintingTimeResponse.totalPrintingTimeSeconds;
    return {
      ...state,
      [systemGroup]: {
        ...state[systemGroup],
        printingTimePerUnit: printingTimePerUnit,
        totalPrintingTime: totalPrintingTime,
      },
    };
  })
  .handleAction(jobAnalysisTotalImpressionsKPIAC.request, (state, action) => {
    if (action.payload.isSilent) {
      return { ...state };
    }
    return {
      ...state,
      [action.payload.SystemGroupType]: {
        totalProduction: undefined,
      },
    };
  })
  .handleAction(jobAnalysisTotalImpressionsKPIAC.success, (state, action) => {
    const systemGroup = action.payload.SystemGroupType;
    return {
      ...state,
      [systemGroup]: {
        ...state[systemGroup],
        totalProduction: action.payload.data.totalProduction,
      },
    };
  })
  .handleAction(getMaxKeepAliveRequestAC.request, (state, action) => {
    if (action.payload.isSilent) {
      return { ...state };
    }
    return {
      ...state,
      [action.payload.systemGroup]: {
        ...state[action.payload.systemGroup],
        maxKeepAlive: undefined,
        maxKeepAliveUtc: undefined,
      },
    };
  })
  .handleAction(getMaxKeepAliveRequestAC.success, (state, action) => {
    return {
      ...state,
      [action.payload.systemGroup]: {
        ...state[action.payload.systemGroup],
        maxKeepAlive: action.payload.maxKeepAlive,
        maxKeepAliveUtc: action.payload.maxKeepAliveUtc,
      },
    };
  })
  .handleAction(getMaxKeepAliveRequestAC.failure, (state, action) => {
    return {
      ...state,
      [action.payload.systemGroup]: {
        ...state[action.payload.systemGroup],
        maxKeepAlive: undefined,
        maxKeepAliveUtc: undefined,
      },
    };
  })
  .handleAction(jobAnalysisTotalBottlesLoadedKPIAC.request, (state, action) => {
    if (action.payload.isSilent) {
      return {
        ...state,
      };
    }
    return {
      ...state,
      [action.payload.SystemGroupType]: {
        ...state[action.payload.SystemGroupType],
        bottlesLoaded: undefined,
      },
    };
  })
  .handleAction(jobAnalysisTotalBottlesLoadedKPIAC.success, (state, action) => {
    return {
      ...state,
      [action.payload.systemGroup]: {
        ...state[action.payload.systemGroup],
        bottlesLoaded: action.payload.bottles,
      },
    };
  });
export default reducer;

//*selectors
const jobAnalysisSelector = (state: Store) => state.jobAnalysis;

export const jobAnalysisBySystemGroupSelector = createSelector(
  [jobAnalysisSelector, systemGroupSelector],
  (jobAnalysis, systemGroup) => {
    return jobAnalysis[systemGroup];
  }
);
export const jobAnalysisKeepAliveSelector = createSelector(
  [jobAnalysisBySystemGroupSelector],
  jobAnalysis => {
    return jobAnalysis['maxKeepAlive'];
  }
);
export const isJobAnalysisChartsLoadingSelector = createSelector(
  [jobAnalysisBySystemGroupSelector],
  jobAnalysis => {
    return jobAnalysis['isJobAnalysisChartsLoading'];
  }
);
export const isJobAnalysisChartsLoadedSelector = createSelector(
  [jobAnalysisBySystemGroupSelector],
  jobAnalysis => {
    return jobAnalysis['isJobAnalysisChartsLoaded'];
  }
);
export const jobAnalysisBySystemGroupIsValidToDownloadInkConsuptionPerJobIsLoadingSelector = createSelector(
  [jobAnalysisBySystemGroupSelector],
  jobAnalysisByGroup => jobAnalysisByGroup['isInkConsumptionPerJobValidToDownloadLoading']
);
export const jobAnalysisBySystemGroupIsValidToDownloadInkConsuptionPerJobSelector = createSelector(
  [jobAnalysisBySystemGroupSelector],
  jobAnalysisByGroup => jobAnalysisByGroup['isInkConsumptionPerJobValidToDownloadMessage']
);
export const jobAnalysisBySystemGroupDownloadInkConsumptionPerJobErrorSelector = createSelector(
  [jobAnalysisBySystemGroupSelector],
  jobAnalysisByGroup => {
    return jobAnalysisByGroup['downloadInkConsumptionPerJobError'];
  }
);
export const jobAnalysisIsDownloadInkConsumptionPerJobLoadingSelector = createSelector(
  [jobAnalysisBySystemGroupSelector],
  jobAnalysisByGroup => {
    return jobAnalysisByGroup['isDownloadInkConsumptionPerJobLoading'];
  }
);
export const jobAnalysisIsDownloadInkConsumptionPerJobLoadedSelector = createSelector(
  [jobAnalysisBySystemGroupSelector],
  jobAnalysisByGroup => {
    return jobAnalysisByGroup['isDownloadInkConsumptionPerJobLoaded'];
  }
);
export const jobAnalysisMediaWithResponseBySystemGroup = createSelector(
  [jobAnalysisBySystemGroupSelector],
  jobAnalysis => {
    return jobAnalysis['mediaWithResponse'];
  }
);

export const jobAnalysisInkConsumptionPerUnitBySystemGroup = createSelector(
  [jobAnalysisBySystemGroupSelector],
  jobAnalysis => {
    return jobAnalysis['inkConsumptionPerUnit'];
  }
);

export const jobAnalysisPrintingTimePerUnitBySystemGroup = createSelector(
  [jobAnalysisBySystemGroupSelector],
  jobAnalysis => {
    return jobAnalysis['printingTimePerUnit'];
  }
);

export const jobAnalysisBottleLoadedSelector = createSelector(
  [jobAnalysisBySystemGroupSelector],
  jobAnalysis => {
    return jobAnalysis['bottlesLoaded'];
  }
);
export const jobAnalysisTotalPrintingTimeSelector = createSelector(
  [jobAnalysisBySystemGroupSelector],
  jobAnalysis => {
    return jobAnalysis['totalPrintingTime'];
  }
);
export const jobAnalysisTotalProductionSelector = createSelector(
  [jobAnalysisBySystemGroupSelector],
  jobAnalysis => {
    return jobAnalysis['totalProduction'];
  }
);
export const jobAnalysisInkConsumptionChartSelector = createSelector(
  [jobAnalysisBySystemGroupSelector],
  jobAnalysis => {
    return jobAnalysis['inkConsumptionChart'];
  }
);
export const jobAnalysisColorConsumptionChartSelector = createSelector(
  [jobAnalysisBySystemGroupSelector],
  jobAnalysis => {
    return jobAnalysis['colorConsumptionChart'];
  }
);
export const jobAnalysisColorCompareChartSelector = createSelector(
  [jobAnalysisBySystemGroupSelector],
  jobAnalysis => {
    return jobAnalysis['colorCompareChart'];
  }
);
export const jobAnalysisSelectedColorsSelector = createSelector(
  [jobAnalysisBySystemGroupSelector],
  jobAnalysis => {
    return jobAnalysis['selectedColors'];
  }
);

export const isTotalCardsLoadingSelector = createSelector(
  [jobAnalysisBySystemGroupSelector],
  jobAnalysis => {
    return jobAnalysis['isTotalCardsLoading'];
  }
);
export const shouldBeUpdatedAfterGroupSelectionSelector = createSelector(
  [jobAnalysisBySystemGroupSelector],
  jobAnalysis => {
    return jobAnalysis['shouldBeUpdatedAfterGroupSelection'];
  }
);
