import { createSelector } from 'reselect';
import { createReducer } from 'typesafe-actions';
import { Store, SystemPageState } from 'store/storeTypes';
import {
  getTotalBottlesLoadedAC,
  getSystemPageCardsDataAC,
  getSystemPageMessagesAC,
  getTimeLineDataAC,
  toggleDayTimelineView,
  incrementTimeLineHour,
  decrementTimeLineHour,
  setTimeLineHour,
  toggleAMPMmodificator,
  getTimelineDayViewRequestAC,
  getQualisetDataAC,
  getImpressionsCardsAC,
  getAvailabilityCardAC,
  getAchievementCardAC,
} from 'actions/systemPageActions';
import { setTimeframeAC } from 'actions/reportFilterActions';

const INIT_STATE = {
  isTopCardsLoading: false,
  isLoading: true,
  isCommonErrorsLoading: false,
  isCommonMaintenanceEventsLoading: false,
  isAchievementCardLoading: false,

  isTimeLineDayLoading: false,
  isTimeLineDayLoaded: false,
  errorTimeLineDay: '',
  timeLineDataDay: null,

  isTimeLineWeekLoading: false,
  isTimeLineWeekLoaded: false,
  errorTimeLineWeek: '',
  timeLineDataWeek: null,

  timeLineDayView: 'day',
  timeLineAMPMmodificator: 'PM',
  timeLineSelectedHour: 18,
  error: '',
  loadedBottles: [],
  errorMessages: [],
  commonMaintenanceEvents: [],
  achievement: 0,
  productionRateChangeLastWeek: 0,
  productionRateChangeFleet: 0,
  productionRate: 0,
  productionChangeLastWeek: 0,
  productionChangeFleet: 0,
  production: 0,
  printerImage: '',
  lastStatus: '',
  lastSoftwareUpdate: '',
  availabilityChangeLastWeek: 0,
  availabilityChangeFleet: 0,
  availability: 0,
  appVersion: '',
  errorDuration: 0,
  maintenanceDuration: 0,
  isQualisetLoading: false,
  isQualisetError: '',
  qualisetData: undefined,
  isNotReportingPeriod: false,
  availabilityTotalDurationReported: 0,
  systemModel: '',
};

const reducer = createReducer<SystemPageState, any>(INIT_STATE)
  .handleAction(getQualisetDataAC.request, (state, action) => {
    return { ...state, isQualisetLoading: true, isQualisetError: '', qualisetDate: undefined };
  })
  .handleAction(getQualisetDataAC.failure, (state, error) => {
    return { ...state, isQualisetLoading: false, isQualisetError: error, qualisetDate: undefined };
  })
  .handleAction(getQualisetDataAC.success, (state, action) => {
    return {
      ...state,
      isQualisetLoading: false,
      isQualisetError: '',
      qualisetData: action.payload,
    };
  })
  .handleAction(setTimeframeAC, (state, action) => {
    const { timeframe, startDate, selectedSystemGroup } = action.payload;
    if (!startDate) {
      return { ...state };
    }

    return {
      ...state,

      isTimeLineDayLoading: false,
      isTimeLineDayLoaded: false,
      errorTimeLineDay: '',
      timeLineDataDay: null,

      isTimeLineWeekLoading: false,
      isTimeLineWeekLoaded: false,
      errorTimeLineWeek: '',
      timeLineDataWeek: null,
    };
  })
  .handleAction(getTimelineDayViewRequestAC.request, (state, action) => {
    return {
      ...state,
      isTimeLineDayLoading: true,
      isTimeLineDayLoaded: false,
      errorTimeLineDay: '',
      timeLineDataDay: null,
    };
  })
  .handleAction(getTimelineDayViewRequestAC.success, (state, action) => {
    return {
      ...state,
      isTimeLineDayLoading: false,
      isTimeLineDayLoaded: true,
      errorTimeLineDay: '',
      timeLineDataDay: action.payload,
    };
  })
  .handleAction(getTimelineDayViewRequestAC.failure, (state, action) => {
    return {
      ...state,
      isTimeLineLoading: true,
      isTimeLineLoaded: false,
      errorTimeLine: 'request failed',
      timeLineDataDay: null,
    };
  })
  .handleAction(getTimeLineDataAC.request, (state, action) => {
    return {
      ...state,
      isTimeLineWeekLoading: true,
      isTimeLineWeekLoaded: false,
      errorTimeLineWeek: '',
      timeLineDataWeek: null,
    };
  })
  .handleAction(getTimeLineDataAC.success, (state, action) => {
    return {
      ...state,
      isTimeLineWeekLoading: false,
      isTimeLineWeekLoaded: true,
      errorTimeLineWeek: '',
      timeLineDataWeek: action.payload,
    };
  })
  .handleAction(getTimeLineDataAC.failure, (state, action) => {
    return {
      ...state,
      isTimeLineWeekLoading: true,
      isTimeLineWeekLoaded: false,
      errorTimeLine: 'request failed',
      timeLineDataWeek: null,
    };
  })

  .handleAction(toggleAMPMmodificator, (state, action) => {
    const { currentHour, currentModificator } = action.payload;
    const nextModificator = currentModificator === 'AM' ? 'PM' : 'AM';
    let nextHour;
    if (nextModificator === 'AM') {
      nextHour = currentHour - 12;
    }
    if (nextModificator === 'PM') {
      nextHour = currentHour + 12;
    }
    return {
      ...state,
      timeLineAMPMmodificator: nextModificator,
      timeLineSelectedHour: nextHour,
    };
  })
  .handleAction(setTimeLineHour, (state, action) => {
    const { value, modificator } = action.payload;
    let nextHourValue;
    if (modificator === 'PM') {
      nextHourValue = value === 12 ? 12 : value > 12 ? value : value + 12;
    } else if (modificator === 'AM') {
      nextHourValue = value;
    }
    return { ...state, timeLineSelectedHour: nextHourValue, timeLineAMPMmodificator: modificator };
  })

  .handleAction(decrementTimeLineHour, (state, action) => {
    const MAX_VALUE = 23;
    const MIN_VALUE = 0;
    const currentHour = +state.timeLineSelectedHour;
    const nextValue = currentHour <= MIN_VALUE ? MAX_VALUE : currentHour - 1;
    const modificator = nextValue >= 12 ? 'PM' : 'AM';

    return { ...state, timeLineSelectedHour: nextValue, timeLineAMPMmodificator: modificator };
  })
  .handleAction(incrementTimeLineHour, (state, action) => {
    const MAX_VALUE = 23;
    const MIN_VALUE = 0;
    const currentHour = +state.timeLineSelectedHour;
    const nextValue = currentHour >= MAX_VALUE ? MIN_VALUE : currentHour + 1;
    const modificator = nextValue >= 12 ? 'PM' : 'AM';
    return { ...state, timeLineSelectedHour: nextValue, timeLineAMPMmodificator: modificator };
  })

  .handleAction(toggleDayTimelineView, (state, action) => {
    const currentView = state.timeLineDayView;
    const nextView = currentView === 'day' ? 'hour' : 'day';
    return { ...state, timeLineDayView: nextView };
  })

  .handleAction(getTotalBottlesLoadedAC.request, (state, action) => {
    if (action.payload.isSilent) {
      return { ...state };
    }
    return { ...state, isLoading: true, error: '' };
  })
  .handleAction(getTotalBottlesLoadedAC.success, (state, action) => {
    return { ...state, isLoading: false, error: '', loadedBottles: action.payload };
  })
  .handleAction(getTotalBottlesLoadedAC.failure, (state, error) => {
    return { ...state, isLoading: false };
  })
  .handleAction(getSystemPageMessagesAC.request, (state, action) => {
    if (action.payload.isSilent) {
      return { ...state };
    } else
      return {
        ...state,
        error: '',
        isCommonErrorsLoading: true,
        isCommonMaintenanceEventsLoading: true,
      };
  })
  .handleAction(getSystemPageMessagesAC.success, (state, action) => {
    return {
      ...state,
      error: '',
      isCommonErrorsLoading: false,
      isCommonMaintenanceEventsLoading: false,
      errorMessages: action.payload.errorTableData.messageTableData,
      commonMaintenanceEvents: action.payload.maintenanceTableData.messageTableData,
    };
  })
  .handleAction(getSystemPageMessagesAC.failure, (state, error) => {
    return {
      ...state,
      isCommonErrorsLoading: false,
      isCommonMaintenanceEventsLoading: false,
      error,
    };
  })
  .handleAction(getSystemPageCardsDataAC.request, (state, action) => {
    return { ...state, isAchievementCardLoading: true, isTopCardsLoading: true, error: '' };
  })
  .handleAction(getSystemPageCardsDataAC.success, (state, action) => {
    const {
      achievement,
      productionRateChangeLastWeek,
      productionRateChangeFleet,
      productionRate,
      productionChangeLastWeek,
      productionChangeFleet,
      production,
      printerImage,
      lastStatus,
      lastSoftwareUpdate,
      availabilityChangeLastWeek,
      availabilityChangeFleet,
      availability,
      appVersion,
      errorDuration,
      errorPercentage,
      maintenanceDuration,
      maintenancePercentage,
      productivity,
      productivityChangeFleet,
      productivityChangeLastWeek,
      isNotReportingPeriod,
      availabilityTotalDurationReported,
    } = action.payload;
    return {
      ...state,
      isAchievementCardLoading: false,
      achievement,
      productionRateChangeLastWeek,
      productionRateChangeFleet,
      productionRate,
      productionChangeLastWeek,
      productionChangeFleet,
      production,
      printerImage,
      lastStatus,
      lastSoftwareUpdate,
      availabilityChangeLastWeek,
      availabilityChangeFleet,
      availability,
      appVersion,
      errorDuration,
      errorPercentage,
      maintenanceDuration,
      maintenancePercentage,
      productivity,
      productivityChangeFleet,
      productivityChangeLastWeek,
      isTopCardsLoading: false,
      isNotReportingPeriod,
      availabilityTotalDurationReported,
    };
  })
  .handleAction(getSystemPageCardsDataAC.failure, (state, error) => {
    return { ...state, isAchievementCardLoading: false, isTopCardsLoading: false, error };
  })
  .handleAction(getImpressionsCardsAC.request, (state, action) => {
    if (action.payload.isSilent) {
      return { ...state };
    } else {
      return { ...state, isImpressionCardsLoading: true, error: '' };
    }
  })
  .handleAction(getImpressionsCardsAC.success, (state, action) => {
    const payload = action.payload.data;

    return {
      ...state,
      isImpressionCardsLoading: false,
      production: payload.production,
      productionChangeFleet: payload.productionChangeFleet,
      productionChangeLastWeek: payload.productionChangeLastWeek,
      productionRate: payload.productionRate,
      productionRateChangeFleet: payload.productionRateChangeFleet,
      productionRateChangeLastWeek: payload.productionRateChangeLastWeek,
      productivity: payload.productivity,
      productivityChangeFleet: payload.productivityChangeFleet,
      productivityChangeLastWeek: payload.productivityChangeLastWeek,
    };
  })
  .handleAction(getAchievementCardAC.request, (state, action) => {
    if (action.payload.isSilent) {
      return { ...state };
    } else {
      return {
        ...state,
        isAchievementCardLoading: true,
      };
    }
  })
  .handleAction(getAchievementCardAC.success, (state, action) => {
    return { ...state, isAchievementCardLoading: false, achievement: action.payload.achievement };
  })

  .handleAction(getAvailabilityCardAC.request, (state, action) => {
    if (action.payload.isSilent) {
      return { ...state };
    } else {
      return {
        ...state,
        isTimeLineDayLoading: true,
        isTimeLineWeekLoading: true,
        isAvailabilityCardLoading: true,
        errorTimeLineDay: '',
        timeLineDataDay: null,
        error: '',
        errorTimeLineWeek: '',
        timeLineDataWeek: null,
      };
    }
  })

  .handleAction(getAvailabilityCardAC.success, (state, action) => {
    const { payload } = action;
    if (payload.day) {
      return {
        ...state,
        isAvailabilityCardLoading: false,
        isTimeLineDayLoading: false,
        isTimeLineDayLoaded: true,
        errorTimeLineDay: '',
        availability: payload.systemPageAvailabilityResponse.availability,
        availabilityChangeFleet: payload.systemPageAvailabilityResponse.availabilityChangeFleet,
        availabilityChangeLastWeek:
          payload.systemPageAvailabilityResponse.availabilityChangeLastWeek,
        errorDuration: payload.systemPageAvailabilityResponse.errorDuration,
        errorPercentage: payload.systemPageAvailabilityResponse.errorPercentage,
        maintenancePercentage: payload.systemPageAvailabilityResponse.maintenancePercentage,
        lastStatus: payload.systemPageAvailabilityResponse.lastStatus,
        maintenanceDuration: payload.systemPageAvailabilityResponse.maintenanceDuration,
        timeLineDataDay: action.payload.systemTimeLineDayResponse,
      };
    }
    if (payload.week) {
      const weekTimeLine = {
        resultSummaryDataArray: payload.resultSummaryDataArray,
        resultTimeLineDataArray: payload.resultTimeLineDataArray,
      };
      return {
        ...state,
        isAvailabilityCardLoading: false,
        isTimeLineWeekLoading: false,
        isTimeLineWeekLoaded: true,
        errorTimeLineWeek: '',

        availability: payload.systemPageAvailabilityResponse.availability,
        availabilityChangeFleet: payload.systemPageAvailabilityResponse.availabilityChangeFleet,
        availabilityChangeLastWeek:
          payload.systemPageAvailabilityResponse.availabilityChangeLastWeek,
        errorDuration: payload.systemPageAvailabilityResponse.errorDuration,
        lastStatus: payload.systemPageAvailabilityResponse.lastStatus,
        maintenanceDuration: payload.systemPageAvailabilityResponse.maintenanceDuration,
        timeLineDataWeek: weekTimeLine,
      };
    }
  });

export default reducer;

//*selectors
const systemPageStateSelector = (state: Store) => {
  return state.systemPage;
};
export const isImpressionCardsLoadingSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage.isImpressionCardsLoading
);
export const isAvailabilityCardLoadingSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage.isAvailabilityCardLoading
);
export const isQualisetLoadingSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage.isQualisetLoading
);
export const qualisetDataSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage.qualisetData
);
export const isNotReportingPeriodSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage.isNotReportingPeriod
);
export const availabilityTotalDurationReportedSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage.availabilityTotalDurationReported
);
export const timeLineSelectedHourSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage['timeLineSelectedHour']
);
export const timeLineAMPMmodificatorSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage['timeLineAMPMmodificator']
);
export const timeLineDayViewSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage['timeLineDayView']
);
export const isCommonMaintenanceEventsLoadingSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage['isCommonMaintenanceEventsLoading']
);

export const commonMaintenanceEventsSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage['commonMaintenanceEvents']
);

export const loadedBottlesSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage['loadedBottles']
);

export const errorMessagesSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage['errorMessages']
);

export const isCommonErrorsLoadingSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage['isCommonErrorsLoading']
);

export const systemPageisLoadingSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage['isLoading']
);
export const isTopCardsLoadingSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage.isTopCardsLoading
);
export const isAchievementCardLoadingSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage.isAchievementCardLoading
);
export const achievementSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage.achievement
);
export const productionRateChangeLastWeekSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage['productionRateChangeLastWeek']
);

export const productionRateChangeFleetSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage['productionRateChangeFleet']
);
export const productionRateSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage['productionRate']
);

export const productionChangeLastWeekSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage['productionChangeLastWeek']
);

export const productionChangeFleetSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage['productionChangeFleet']
);
export const productivitySelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage['productivity']
);
export const productivityChangeFleetSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage['productivityChangeFleet']
);
export const productivityChangeLastWeekSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage['productivityChangeLastWeek']
);
export const productionSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage['production']
);

export const printerImageSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage['printerImage']
);

export const lastStatusSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage['lastStatus']
);

export const lastSoftwareUpdateSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage['lastSoftwareUpdate']
);

export const availabilityChangeLastWeekSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage['availabilityChangeLastWeek']
);

export const availabilityChangeFleetSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage['availabilityChangeFleet']
);

export const availabilitySelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage['availability']
);
export const errorDurationSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage['errorDuration']
);
export const errorPercentageSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage['errorPercentage']
);
export const maintenanceDurationSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage['maintenanceDuration']
);
export const maintenancePercentageSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage['maintenancePercentage']
);
export const appVersionSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage['appVersion']
);

export const timeLineDataDaySelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage['timeLineDataDay']
);
export const isTimeLineDayLoadingSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage['isTimeLineDayLoading']
);
export const isTimeLineDayLoadedSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage['isTimeLineDayLoaded']
);

export const timeLineDataWeekSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage['timeLineDataWeek']
);
export const isTimeLineWeekLoadingSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage['isTimeLineWeekLoading']
);
export const isTimeLineWeekLoadedSelector = createSelector(
  [systemPageStateSelector],
  systemPage => systemPage['isTimeLineWeekLoaded']
);
