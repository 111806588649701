import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import { authService } from 'services/AuthProvider';
import { makeApiUrl } from 'actions/_shared/utils';
import apiAction from 'actions/_shared/apiAction';
import {
  ApolloCard,
  DtfCardsName,
  DtgCardColors,
  DtgSizes,
  ModeDTFTypeCards,
  SizeCategories,
  SizeDTGCardType,
  UserSystemGroup,
  UserSystemGroupStrings,
} from 'store/storeTypes';
import { ReportDateRequest } from 'models/ReportsFilterData';
import { getCustomSearchParameters } from 'helpers/date';
import { getRealTimeHeaders } from './_shared/realTimeHeaders';
import { processApolloJobSummaryData, processDtgJobSummaryData } from 'helpers/jobAnalysisUtils';

export type GetJobSummaryRequest = {
  systemGroupType: number;
  dtgFilterParameters: {
    impressionSize: Array<number>;
    mediaSize: Array<number>;
  };
  systems: Array<string>;
  custom: any;
  isRealTime?: boolean;
} & ReportDateRequest;
export type getJobSummaryCardsResponse = {
  jobSummaryData: Array<SizeDTGCardType>;
  systemGroup: string;
};
export const clearCardSelection = createStandardAction('CLEAR_CARD_SELECTION')<{
  selectedSystemGroup: UserSystemGroupStrings;
}>();
export const selectApolloCard = createStandardAction('SELECT_APOLLO_CARD')<{
  sizeCategory: SizeCategories;
  selectedSystemGroup: UserSystemGroupStrings;
}>();
//sync actions jobsSummary

export const toggleSelectionSizeCard = createStandardAction('TOGGLE_SELECTION_SIZE_CARD')<{
  id: string;
  selectedSystemGroup: UserSystemGroupStrings;
}>();
export const toggleSelectionSizeGroupCards = createStandardAction(
  'TOGGLE_SELECTION_SIZE_GROUP_CARDS'
)<{
  nameSet: string;
  selectedSystemGroup: UserSystemGroupStrings;
}>();
export const clearSelectionSizeCards = createStandardAction('CLEAR_SELECTION_SIZE_CARDS')();
//sync actions modesSummary
export const selectDTFCard = createStandardAction('SELECET_DTF_CARD')<{
  cardHeader: string;
  percentOfAllJob: number;
}>();
export const highlightDTFCard = createStandardAction('HIGHLIGHT_DTF_CARD')<{
  mode: number;
  resolution: string;
}>();

//async actions jobsSummary
export const getJobSummaryCardsAC = createAsyncAction(
  'GET_JOB_SUMMARY_CARDS_STARTED',
  'GET_JOB_SUMMARY_CARDS_SUCCESS',
  'GET_JOB_SUMMARY_CARDS_FAILED'
)<GetJobSummaryRequest, getJobSummaryCardsResponse, Error>();

export const getJobSummaryCardsRequest = (
  {
    week,
    month,
    quarter,
    year,
    day,
    custom,
    dtgFilterParameters,
    systemGroupType,
    systems,
  }: GetJobSummaryRequest,
  isRealTime = false
) => {
  const url = makeApiUrl('/api/job-analysis-report/job-summary');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub) {
    const customTimeframeParameters = getCustomSearchParameters(custom);

    return apiAction({
      request: {
        url,
        method: 'POST',
        headers: getRealTimeHeaders(isRealTime),
        params: {
          week,
          month,
          quarter,
          year,
          day,
          custom: customTimeframeParameters,
          dtgFilterParameters,
          systemGroupType,
        },
        data: {
          systems,
        },
      },
      logic: {
        onFailed: (error, dispatch) => {
          dispatch(getJobSummaryCardsAC.failure(error));
        },
        onStarted: dispatch => {
          dispatch(
            getJobSummaryCardsAC.request({
              week,
              month,
              quarter,
              year,
              custom,
              dtgFilterParameters,
              systemGroupType,
              systems,
              isRealTime,
            })
          );
        },
        onSuccess: (data, dispatch) => {
          const { systemGroup } = data;
          if (systemGroup === UserSystemGroup.DTG) {
            const { jobSummaryData } = data;
            const updatedJobSummaryData = processDtgJobSummaryData(jobSummaryData);
            data.jobSummaryData = updatedJobSummaryData;
            dispatch(getJobSummaryCardsAC.success(data));
          }
          if (systemGroup === UserSystemGroup.Apollo) {
            const cards = processApolloJobSummaryData(data.jobSummaryDataShort);
            dispatch(getJobSummaryCardsAC.success({ ...data, jobSummaryDataShort: cards }));
          }
        },
      },
    });
  }
};

//async actions modesSummary
export type ModeSummaryCardsRequest = {
  systemGroupType: number;
  dtfFilterParameters: {
    printMode: Array<number>;
  };
  systems: Array<string>;
} & ReportDateRequest;

export type ModeSummaryCardsResponse = {
  systemGroup: string;
  jobSummaryData: Array<ModeDTFTypeCards>;
};
export const getModeSummaryCardsAC = createAsyncAction(
  'GET_MODE_SUMMARY_CARDS_STARTED',
  'GET_MODE_SUMMARY_CARDS_SUCCESS',
  'GET_MODE_SUMMARY_CARDS_FAILED'
)<ModeSummaryCardsRequest, ModeSummaryCardsResponse, Error>();

export const getModeSummaryCardsRequest = ({
  week,
  month,
  quarter,
  year,
  day,
  custom,
  systemGroupType,
  dtfFilterParameters,
  systems,
}: ModeSummaryCardsRequest) => {
  const url = makeApiUrl('/api/job-analysis-report/job-summary');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub) {
    //@ts-ignore
    const customTimeframeParameters = getCustomSearchParameters(custom);

    return apiAction({
      request: {
        url,
        method: 'POST',
        params: {
          week,
          day,
          custom: customTimeframeParameters,
          month,
          quarter,
          year,
          systemGroupType,
          dtfFilterParameters,
        },
        data: {
          systems,
        },
      },
      logic: {
        onFailed: (error, dispatch) => {
          return dispatch(getModeSummaryCardsAC.failure(error));
        },
        onStarted: dispatch => {
          return dispatch(
            getModeSummaryCardsAC.request({
              week,
              month,
              quarter,
              year,
              day,
              systemGroupType,
              dtfFilterParameters,
              systems,
            })
          );
        },
        onSuccess: (data, dispatch) => {
          data.jobSummaryData.forEach((el: any) => {
            let name = '';
            if (el.mode === 0) {
              name = DtfCardsName.Production;
            }
            if (el.mode === 1) {
              name = DtfCardsName.ProdSolid;
            }
            if (el.mode === 2) {
              name = DtfCardsName.Quality;
            }
            if (el.mode === 3) {
              name = DtfCardsName.QualitySolid;
            }

            el['modeName'] = name;
          });

          return dispatch(getModeSummaryCardsAC.success(data));
        },
      },
    });
  }
};

//sync ModeSummary (DTF) actions
export const clearDtfCardsSelection = createStandardAction('CLEAR_DTF_CARDS_SELECTION')();
export const setDtfPageSelectorToDefaulBySizeState = createStandardAction(
  'SET_DTF_PAGE_SELECTOR_TO_DEFAULT_BY_SIZE_STATE'
)();
