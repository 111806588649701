import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckedIcon } from '../../assets/icons/CheckedIcon.svg';
import { ReactComponent as HalfCheckedIcon } from '../../assets/icons/HalfCheckedIcon.svg';
import { ReactComponent as FilterUnfoldIcon } from '../../assets/icons/FilterUnfoldIcon.svg';
import { ReactComponent as FilterFoldIcon } from '../../assets/icons/FilterFoldIcon.svg';
import ModelRow from './ModelRow';
import {
  ReportFilterData,
  ReportFilterState,
  ReportModel,
  ReportSystem,
  UserSystemGroupStrings,
} from 'store/storeTypes';
import { SinglePrinterSelectionPayload } from 'actions/reportFilterActions';
import { StyledTooltip } from 'globalStyle';

// width: 300px;
export const RowWrapper = styled.div<{ isSiteRow?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 46px;
  min-height: 46px;
  width: 100%;
  padding: 0 10px;
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: normal;
  color: rgb(53, 64, 82);
  border-top: ${props => (props.isSiteRow ? ' 1px solid #c5d0de' : 'none')};
`;
export const LeftPart = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  height: 100%;
  width: 250px;
`;
export const CheckBox = styled.div<{ checked: boolean }>`
  box-sizing: border-box;
  padding-bottom: 1px;
  padding-right: 1px;
  width: 16px;
  height: 16px;
  border: ${props => (props.checked ? ' 1px solid transparent' : '1px solid rgb(53, 64, 82)')};
  border-radius: 3px;
  background-color: ${props => (props.checked ? '#2ea1f8' : 'FFFFFF')};
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const NameWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-left: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 150px;
`;
export const NameSpan = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
export const RightPart = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  height: 100%;
  width: 20px;
`;

type SiteRowProps = {
  id: string;
  name: string;
  selectedSystemGroup: UserSystemGroupStrings;
  filterState: ReportFilterState;
  filterData: ReportFilterData;
  filteredModels: ReportModel[];
  filteredSerials: ReportSystem[];
  filterValue: string;
  isSignleSelectionMode: boolean;
  toggleFilterRowSystemsSelection: ({}) => void;
  toggleFilterModelRowSystemsSelection: ({}) => void;
  toggleFilterSerialRowSystemSelection: ({}) => void;
  singlePrinterSelection: ({}: SinglePrinterSelectionPayload) => void;
};

const SiteRow = ({
  id,
  name,
  selectedSystemGroup,
  filterState,
  filterData,
  filteredModels,
  filteredSerials,
  filterValue,
  isSignleSelectionMode,
  singlePrinterSelection,
  toggleFilterRowSystemsSelection,
  toggleFilterModelRowSystemsSelection,
  toggleFilterSerialRowSystemSelection,
}: SiteRowProps) => {
  const { t } = useTranslation();
  const [isFolded, setFolded] = useState(false);
  const isThisRowSelected = filterState.selectedSiteIds.includes(id);
  const siteModels = filterData && filterData.models.filter(model => model.siteId === id);
  const filterModelsForThisSite =
    filteredModels && filteredModels.filter(model => model.siteId === id);
  const siteSystemSerialIds = filterData.systems
    .filter(system => system.siteId === id)
    .map(system => system.name);
  const siteSelectedSystemSerialIds = filterState.selectedSystemSerials.filter(selectedSerial =>
    siteSystemSerialIds.includes(selectedSerial)
  );
  const onClick = () => {
    if (!isSignleSelectionMode) {
      toggleFilterRowSystemsSelection({
        selectedSystemGroup,
        filterData,
        filterState,
        siteId: id,
      });
    }
  };
  const getCheckedIcon = (totalCount: number, selectedCount: number) => {
    if (totalCount === selectedCount) {
      return <CheckedIcon fill="white" />;
    }
    if (selectedCount > 0 && selectedCount < totalCount) {
      return <HalfCheckedIcon fill="white" style={{ marginTop: '1px' }} />;
    }
  };
  const CheckedIconJSX = getCheckedIcon(
    siteSystemSerialIds.length,
    siteSelectedSystemSerialIds.length
  );

  return (
    <>
      <StyledTooltip
        disableHoverListener={!isSignleSelectionMode}
        title={t('tooltip.xmr.singleSelection')}>
        <RowWrapper isSiteRow={true} data-automationid={name}>
          <LeftPart onClick={onClick}>
            <CheckBox data-testid="check-box" checked={isThisRowSelected}>
              {isThisRowSelected && CheckedIconJSX}
            </CheckBox>
            <NameWrapper>
              <NameSpan data-testid="row-site-name">{name}</NameSpan>
            </NameWrapper>
          </LeftPart>
          <RightPart onClick={() => setFolded(!isFolded)}>
            {isFolded ? <FilterUnfoldIcon /> : <FilterFoldIcon data-testid="folded-icon" />}
          </RightPart>
        </RowWrapper>
      </StyledTooltip>
      {isFolded ? (
        <></>
      ) : (
        <>
          {filterModelsForThisSite
            ? filterModelsForThisSite.map(model => {
                return (
                  <ModelRow
                    key={model.id}
                    {...model}
                    filterState={filterState}
                    filterData={filterData}
                    filterValue={filterValue}
                    selectedSystemGroup={selectedSystemGroup}
                    isSignleSelectionMode={isSignleSelectionMode}
                    singlePrinterSelection={singlePrinterSelection}
                    toggleFilterModelRowSystemsSelection={toggleFilterModelRowSystemsSelection}
                    siteId={id}
                    modelId={model.id}
                    toggleFilterSerialRowSystemSelection={toggleFilterSerialRowSystemSelection}
                    filteredSerials={filteredSerials}
                  />
                );
              })
            : siteModels.map(model => {
                return (
                  <ModelRow
                    key={model.id}
                    {...model}
                    filterState={filterState}
                    filterData={filterData}
                    selectedSystemGroup={selectedSystemGroup}
                    isSignleSelectionMode={isSignleSelectionMode}
                    singlePrinterSelection={singlePrinterSelection}
                    toggleFilterModelRowSystemsSelection={toggleFilterModelRowSystemsSelection}
                    siteId={id}
                    modelId={model.id}
                    toggleFilterSerialRowSystemSelection={toggleFilterSerialRowSystemSelection}
                    filteredSerials={filteredSerials}
                    filteredModels={filteredModels}
                  />
                );
              })}
        </>
      )}
    </>
  );
};
export default SiteRow;
