import { createSelector } from 'reselect';
import { Reducer, combineReducers } from 'redux';
import { createReducer, EmptyAC, PayloadAC } from 'typesafe-actions';

import { actions, RootAction } from '../actions';
import { ApiEndpointState, GenericPropsType, Store, UserSystemGroup } from '../store/storeTypes';
import { getMaxLoadAttemptsEnv } from '../helpers/envGetters';
import { getMaxKeepAliveRequestAC } from 'actions/overviewActions';
import { systemGroupSelector } from './ui/systemGroupUiReducer';

const MAX_ATTEMPTS = getMaxLoadAttemptsEnv();

export const apiStateReducer: Reducer = combineReducers({
  reportsFilterData: createApiStateReducer(
    actions.reportApi.loadReportsFilterSitesStarted,
    actions.reportApi.loadReportsFilterSitesSuccess,
    actions.reportApi.loadReportsFilterSitesFailed
  ),
  reportsCurrentState: createApiStateReducer(
    actions.reportApi.loadReportsCurrentStateStarted,
    actions.reportApi.loadReportsCurrentStateSuccess,
    actions.reportApi.loadReportsCurrentStateFailed
  ),
});

function createApiStateReducer(
  startedAction:
    | EmptyAC<'LOAD_REPORTS_FILTER_SITES_STARTED'>
    | EmptyAC<'LOAD_REPORTS_CURRRENT_STATE_STARTED'>,
  successAction: PayloadAC<
    'LOAD_REPORTS_FILTER_SITES_SUCCESS' | 'LOAD_REPORTS_CURRRENT_STATE_SUCCESS',
    GenericPropsType
  >,
  failedAction: PayloadAC<
    'LOAD_REPORTS_FILTER_SITES_FAILED' | 'LOAD_REPORTS_CURRRENT_STATE_FAILED',
    Record<string, unknown> | unknown
  >
) {
  const INIT_STATE: ApiEndpointState = {
    isInProgress: false,
    isDone: false,
    loadAttempts: 0,
    reachedMaxLoadAttempts: false,
    dTGLastUpdate: undefined,
    dTFLastUpdate: undefined,
    apolloLastUpdate: undefined,
    isSiteExtensionIsLoaded: false,
  };

  return createReducer<ApiEndpointState, RootAction>(INIT_STATE)
    .handleAction(startedAction, (state, action) => {
      return {
        ...state,
        isInProgress: true,
        isDone: false,
        loadAttempts: state.loadAttempts + 1,
        reachedMaxLoadAttempts: state.loadAttempts + 1 >= MAX_ATTEMPTS,
      };
    })
    .handleAction(successAction, (state, action) => {
      const dTGLastUpdate = action.payload['DTG'] && action.payload['DTG'].lastUpdateLabel;
      const dTFLastUpdate = action.payload['DTF'] && action.payload['DTF'].lastUpdateLabel;
      const apolloLastUpdate = action.payload['Apollo'] && action.payload['Apollo'].lastUpdateLabel;
      const actionType = action.type;
      let isSiteExtensionIsLoaded;
      if (actionType === 'LOAD_REPORTS_FILTER_SITES_SUCCESS') {
        isSiteExtensionIsLoaded = true;
      }

      return {
        ...INIT_STATE,
        isDone: true,
        dTFLastUpdate,
        dTGLastUpdate,
        apolloLastUpdate,
        isSiteExtensionIsLoaded,
      };
    })

    .handleAction(getMaxKeepAliveRequestAC.success, (state, action) => {
      return {
        ...state,
        apolloLastUpdate: action.payload.maxKeepAlive,
      };
    })

    .handleAction(failedAction, (state, action) => {
      return { ...state, isInProgress: false, isDone: false };
    });
}
//*selectors
const apiStateSelector = (state: Store) => {
  return state.apiState;
};
export const reportCurrentStateSelector = createSelector([apiStateSelector], apiState => {
  return apiState['reportsCurrentState'];
});
export const reportCurrentStateIsDoneSelector = createSelector(
  [reportCurrentStateSelector],
  reportsCurrentState => {
    return reportsCurrentState['isDone'];
  }
);
export const reportsFilterDataSelector = createSelector([apiStateSelector], apiState => {
  return apiState['reportsFilterData'];
});

export const reportsFilterDataIsDoneSelector = createSelector(
  [reportsFilterDataSelector],
  reportsFilterData => {
    return reportsFilterData['isDone'];
  }
);

export const reportsFilterDataIsSiteExtensionIsLoadedSelector = createSelector(
  [reportsFilterDataSelector],
  reportsFilterData => {
    return reportsFilterData['isSiteExtensionIsLoaded'];
  }
);
export const dtgLastUpdateSelector = createSelector(
  [reportCurrentStateSelector],
  reportCurrentState => {
    return reportCurrentState['dTGLastUpdate'];
  }
);
export const dtfLastUpdateSelector = createSelector(
  [reportCurrentStateSelector],
  reportCurrentState => {
    return reportCurrentState['dTFLastUpdate'];
  }
);
export const apolloLastUpdateSelector = createSelector(
  [reportCurrentStateSelector],
  reportCurrentState => {
    return reportCurrentState['apolloLastUpdate'];
  }
);
export const lastUpdateBySystemGroupSelector = createSelector(
  [systemGroupSelector, reportCurrentStateSelector],
  (systemGroup, reportCurrentState) => {
    switch (systemGroup) {
      case UserSystemGroup.Apollo:
        return reportCurrentState['apolloLastUpdate'];
      case UserSystemGroup.DTF:
        return reportCurrentState['dTFLastUpdate'];
      case UserSystemGroup.DTG:
        return reportCurrentState['dTGLastUpdate'];
      default:
        return new Date();
    }
  }
);
