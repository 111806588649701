import { tvDashboardPath } from 'contracts/constants';
import { KonnectAnalytics } from 'KonnectAnalytics/konnectAnalytics';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { UserSystemGroup } from 'store/storeTypes';
import styled from 'styled-components';

const NavContainer = styled.div`
  flex-grow: 1;
  display: flex;
  margin-left: 10px;
  @media (max-width: 950px) {
    flex-grow: 0;
    margin-left: 0;
  }
`;

const NavElement = styled(NavLink)`
  display: inline-block;
  background: #2ea1f8;
  border: 1px solid #1878ba;
  font-family: Rubik, sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #c2e1f9;
  padding: 10px 30px 10px 55px;
  text-decoration: none;
  white-space: nowrap;
  position: relative;

  &:not(:last-child) {
    border-right: none;
  }
  &:first-child {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }
  &:last-child {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }
  &.active {
    color: #fff;
    background: #208cd7;
  }
  @media (max-width: 1200px) {
    padding: 10px 10px 10px 30px;
  }
  @media (max-width: 1050px) {
    padding: 10px 5px;
  }
`;

const DesktopNav = styled.div`
  @media (max-width: 950px) {
    display: none;
  }
`;

const DesktopNavIcon = styled.img`
  width: 16px;
  height: 16px;
  position: absolute;
  left: 30px;
  top: 9px;
  @media (max-width: 1200px) {
    left: 5px;
  }
  @media (max-width: 1050px) {
    display: none;
  }
`;
const DesktopShellReportsNav = props => {
  const { navLinks, currentReport, setSystemGroup } = props;

  const onClick = link => {
    // auto switch to apollo system group if navigated to tv dashboard
    if (link.path === tvDashboardPath) {
      setSystemGroup(UserSystemGroup.Apollo);
    }
  };
  return (
    <NavContainer data-automationid={`desktop_navigation`}>
      <DesktopNav>
        {navLinks.map((link, i) => {
          return (
            <NavElement
              onClick={() => onClick(link)}
              to={link.path}
              key={link.path + i}
              data-automationid={`chooseReportButton_${link.text}`}>
              <DesktopNavIcon src={`/img/${link.image}`} /> {link.text}
            </NavElement>
          );
        })}
      </DesktopNav>
    </NavContainer>
  );
};
export default DesktopShellReportsNav;
