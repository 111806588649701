import React from 'react';
import styled from 'styled-components';
import AllBtn from './AllBtn';
import SiteRow from './SiteRow';
import {
  ReportFilterData,
  ReportFilterState,
  ReportModel,
  ReportSite,
  ReportSystem,
  UserSystemGroupStrings,
} from 'store/storeTypes';
import { MainReportTypes } from 'models/ReportData';
import { SinglePrinterSelectionPayload } from 'actions/reportFilterActions';
const Wrapper = styled.div<{ height: number }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${props => props.height}px;
  overflow: hidden;
  position: relative;
`;
const ScrollableContainer = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  &::-webkit-scrollbar {
    width: 9px;
  }
  &::-webkit-scrollbar-track {
    border-left: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: #c5d0de;
    border-radius: 13px;
  }
`;

const LastEmptyBlock = styled.div`
  width: 100%;
  min-height: 70px;
`;
type FilterSystemsPopUpMenuProps = {
  selectedSystemGroup: UserSystemGroupStrings;
  filterState: ReportFilterState;
  filterData: ReportFilterData;
  filterValue: string;

  filteredSite: ReportSite[];
  filteredModel: ReportModel[];
  filteredSystem: ReportSystem[];
  pbiReportHeight: number;
  activeReport: string;
  isMobile: boolean;
  toggleFilterRowSystemsSelection: ({}) => void;
  toggleFilterModelRowSystemsSelection: ({}) => void;
  toggleFilterSerialRowSystemSelection: ({}) => void;
  toggleAllSystemsSelection: ({}) => void;
  singlePrinterSelection: ({}: SinglePrinterSelectionPayload) => void;
};
const FilterSystemsPopUpMenu = ({
  selectedSystemGroup,
  filterState,
  filterData,
  filterValue,
  toggleAllSystemsSelection,
  filteredSite,
  filteredModel,
  filteredSystem,
  pbiReportHeight,
  activeReport,
  toggleFilterRowSystemsSelection,
  toggleFilterModelRowSystemsSelection,
  toggleFilterSerialRowSystemSelection,
  singlePrinterSelection,
}: FilterSystemsPopUpMenuProps) => {
  const isSignleSelectionMode = activeReport === MainReportTypes.TvDashboard;
  const { sites, models, systems } = filterData;
  const getFilteredSitesAndModelsAndSerials = (filterValue: string) => {
    //*if site founded => displaying sites.
    if (filteredSite.length > 0) {
      return { filteredSites: filteredSite, filteredModels: null, filteredSerials: null };
    }
    //*byModel - if model founded display models
    let filteredSitesByFilteredModels = new Array();
    for (let i = 0; i < filteredModel.length; i++) {
      const siteId = filteredModel[i].siteId;
      const foundedSite = sites.find(site =>
        site.name.toLowerCase().includes(siteId.toLowerCase())
      );
      const isSiteAlreadyExist = filteredSitesByFilteredModels.find(
        site => site.id === foundedSite.id
      );
      if (!isSiteAlreadyExist) {
        filteredSitesByFilteredModels.push(foundedSite);
      }
    }
    if (filteredSitesByFilteredModels.length > 0) {
      return {
        filteredSites: filteredSitesByFilteredModels,
        filteredModels: filteredModel,
        filteredSerials: null,
      };
    }
    //*byserialNumber and nick name

    let filteredSitesByFilteredSerials = new Array();
    let filteredModelsByFilteredSerials = new Array();
    let filteredSystemsBySerial = new Array();

    for (let i = 0; i < filteredSystem.length; i++) {
      //*get sites to result object
      const siteId = filteredSystem[i].siteId;
      const site = sites.find(site => site.name === siteId);
      const isSiteAlreadyExist = filteredSitesByFilteredSerials.find(site => site.id === siteId);
      if (!isSiteAlreadyExist) {
        filteredSitesByFilteredSerials.push(site);
      }
      //*get models to result object
      const modelId = filteredSystem[i].modelId;
      const model = models.find(model => model.id === modelId);
      const isModelAlreadyExist = filteredModelsByFilteredSerials.find(
        model => model.id === modelId
      );
      if (!isModelAlreadyExist) {
        filteredModelsByFilteredSerials.push(model);
      }
      //*filter all systems by input starts
      if (filteredSystem[i].name.includes(filterValue)) {
        filteredSystemsBySerial.push(filteredSystem[i]);
      }
    }
    return {
      filteredSites: filteredSitesByFilteredSerials,
      filteredModels: filteredModelsByFilteredSerials,
      filteredSerials: filteredSystemsBySerial,
    };
  };
  const { filteredSites, filteredModels, filteredSerials } = getFilteredSitesAndModelsAndSerials(
    filterValue
  );
  return (
    <Wrapper height={pbiReportHeight - 100} data-automationid="machine-filter-slider">
      <ScrollableContainer>
        <AllBtn
          totalSystemCount={filterData.systems.length}
          selectedSystemsCount={filterState.selectedSystemSerials.length}
          toggleAllSystemsSelection={toggleAllSystemsSelection}
          selectedSystemGroup={selectedSystemGroup}
          filterState={filterState}
          filterData={filterData}
          isSignleSelectionMode={isSignleSelectionMode}
        />
        {filteredSites &&
          filteredSites.map(site => {
            const siteModels =
              filteredModels && filteredModels.filter(model => model.siteId === site.id);
            const siteSerials =
              filteredSerials && filteredSerials.filter(serial => serial.siteId === site.id);
            return (
              <SiteRow
                key={site.id}
                {...site}
                filterState={filterState}
                filterData={filterData}
                filterValue={filterValue}
                selectedSystemGroup={selectedSystemGroup}
                filteredModels={siteModels}
                filteredSerials={siteSerials}
                isSignleSelectionMode={isSignleSelectionMode}
                singlePrinterSelection={singlePrinterSelection}
                toggleFilterRowSystemsSelection={toggleFilterRowSystemsSelection}
                toggleFilterModelRowSystemsSelection={toggleFilterModelRowSystemsSelection}
                toggleFilterSerialRowSystemSelection={toggleFilterSerialRowSystemSelection}
              />
            );
          })}
        <LastEmptyBlock></LastEmptyBlock>
      </ScrollableContainer>
    </Wrapper>
  );
};
export default FilterSystemsPopUpMenu;
