import { createSelector } from 'reselect';
import { createReducer } from 'typesafe-actions';
import _ from 'lodash';

import {
  getOverviewProductionCardsAC,
  getOverviewAchievementsAC,
  getOverviewProductionAllChartAC,
  getOverviewImperssionsAvailabilityCardsAC,
  getOverviewProductionRateCardAC,
  getOverviewProductivityCardAC,
  getOverviewImpressionsAvailabilityChartsAC,
  getOverviewSpeedChartAC,
  getOverviewUtilizationChartAC,
  getOverviewReportMainRequestAC,
  getOverviewAvailabilityRequestAC,
  getMaxKeepAliveRequestAC,
} from 'actions/overviewActions';
import { OverviewByType, Store } from '../store/storeTypes';
import { mergeCopyArrays } from 'helpers/conversions';
import { systemGroupSelector } from './ui/systemGroupUiReducer';
import { jobAnalysisInkConsumptionKPIAC } from 'actions/jobAnalysisActions';
import { getSystemAnalysisAC } from 'actions/systemAnalysisActions';
import { getAvailabilityCardAC } from 'actions/systemPageActions';
import {
  setActiveDateAC,
  setTimeframeAC,
  toggleAllSystemsSelection,
  toggleFilterModelRowSystemsSelection,
  toggleFilterRowSystemsSelection,
  toggleFilterSerialRowSystemSelection,
  unselectSerialsByModel,
} from 'actions/reportFilterActions';

const INIT_STATE: OverviewByType = {
  DTF: {
    totalProduction: undefined,
    productionTrend: undefined,
    productionRate: undefined,
    productionRateTrend: undefined,
    loadingTime: undefined,
    darkImpressionsPercentage: undefined,
    availability: undefined,
    availabilityTrend: undefined,
    averageLoadingTime: undefined,
    errorDuration: undefined,
    maintenanceDuration: undefined,
    record: undefined,
    weeklyCompleted: undefined,
    weeklyGoal: undefined,
    weeklyPercentage: undefined,
    yearlyCompleted: undefined,
    yearlyGoal: undefined,
    yearlyPercentage: undefined,
    darkImpressionsTrend: undefined,
    errorDurationTrend: undefined,
    loadingTimeTrend: undefined,
    maintenanceDurationTrend: undefined,
    productionChart: undefined,
    utilizationChart: undefined,
    speedChart: undefined,
    availabilityChart: undefined,
    preparation: undefined,
    utilization: undefined,
    trendPreparation: undefined,
    trendUtilization: undefined,
    productivity: undefined,
    productivityChange: undefined,
    printingTime: undefined,
    printingTimeTrend: undefined,
    productivityUtilization: undefined,
    productivityUtilizationTrend: undefined,
    timeOfLastMainResponse: undefined,
    timeOfLastAvailabilityResponseResponse: undefined,
    maxKeepAlive: undefined,
  },
  DTG: {
    totalProduction: undefined,
    productionTrend: undefined,
    productionRate: undefined,
    productionRateTrend: undefined,
    loadingTime: undefined,
    darkImpressionsPercentage: undefined,
    availability: undefined,
    availabilityTrend: undefined,
    averageLoadingTime: undefined,
    errorDuration: undefined,
    maintenanceDuration: undefined,
    record: undefined,
    weeklyCompleted: undefined,
    weeklyGoal: undefined,
    weeklyPercentage: undefined,
    yearlyCompleted: undefined,
    yearlyGoal: undefined,
    yearlyPercentage: undefined,
    darkImpressionsTrend: undefined,
    errorDurationTrend: undefined,
    loadingTimeTrend: undefined,
    maintenanceDurationTrend: undefined,
    productionChart: undefined,
    utilizationChart: undefined,
    speedChart: undefined,
    preparation: undefined,
    utilization: undefined,
    availabilityChart: undefined,
    trendPreparation: undefined,
    trendUtilization: undefined,
    productivity: undefined,
    productivityChange: undefined,
    printingTime: undefined,
    printingTimeTrend: undefined,
    productivityUtilization: undefined,
    productivityUtilizationTrend: undefined,
    timeOfLastMainResponse: undefined,
    timeOfLastAvailabilityResponseResponse: undefined,
    maxKeepAlive: undefined,
  },
  Apollo: {
    totalProduction: undefined,
    productionTrend: undefined,
    productionRate: undefined,
    productionRateTrend: undefined,
    loadingTime: undefined,
    darkImpressionsPercentage: undefined,
    availability: undefined,
    availabilityTrend: undefined,
    averageLoadingTime: undefined,
    record: undefined,
    weeklyCompleted: undefined,
    weeklyGoal: undefined,
    weeklyPercentage: undefined,
    yearlyCompleted: undefined,
    yearlyGoal: undefined,
    yearlyPercentage: undefined,
    darkImpressionsTrend: undefined,
    loadingTimeTrend: undefined,
    productionChart: undefined,
    utilizationChart: undefined,
    speedChart: undefined,
    preparation: undefined,
    utilization: undefined,
    availabilityChart: undefined,
    trendPreparation: undefined,
    trendUtilization: undefined,
    productivity: undefined,
    productivityChange: undefined,
    printingTime: undefined,
    printingTimeTrend: undefined,
    productivityUtilization: undefined,
    productivityUtilizationTrend: undefined,
    isNotReportingPeriod: undefined,
    availabilityTotalDurationReported: undefined,
    isOverviewMainRequestLoading: undefined,
    errorDuration: undefined,
    errorDurationTrend: undefined,
    maintenanceDuration: undefined,
    maintenanceDurationTrend: undefined,
    timeOfLastMainResponse: undefined,
    timeOfLastAvailabilityResponseResponse: undefined,
    maxKeepAlive: undefined,
  },
};
const reducer = createReducer<OverviewByType, any>(INIT_STATE)
  .handleAction(
    [
      setTimeframeAC,
      setActiveDateAC,
      unselectSerialsByModel,
      toggleAllSystemsSelection,
      toggleFilterSerialRowSystemSelection,
      toggleFilterModelRowSystemsSelection,
      toggleFilterRowSystemsSelection,
    ],
    (state, action) => {
      return INIT_STATE;
    }
  )

  .handleAction(getOverviewProductionCardsAC.success, (state, action) => {
    return _.merge(_.cloneDeep(state), { ...action.payload });
  })
  .handleAction(getOverviewAchievementsAC.success, (state, action) => {
    return _.merge(_.cloneDeep(state), { ...action.payload });
  })
  .handleAction(getOverviewProductionAllChartAC.success, (state, action) => {
    return _.mergeWith(_.cloneDeep(state), { ...action.payload }, mergeCopyArrays);
  })
  .handleAction(getOverviewProductivityCardAC.success, (state, action) => {
    return _.mergeWith(_.cloneDeep(state), { ...action.payload }, mergeCopyArrays);
  })
  .handleAction(getOverviewProductionRateCardAC.success, (state, action) => {
    return _.mergeWith(_.cloneDeep(state), { ...action.payload }, mergeCopyArrays);
  })
  .handleAction(getOverviewImperssionsAvailabilityCardsAC.success, (state, action) => {
    return _.mergeWith(_.cloneDeep(state), { ...action.payload }, mergeCopyArrays);
  })
  .handleAction(getOverviewUtilizationChartAC.success, (state, action) => {
    return _.mergeWith(_.cloneDeep(state), { ...action.payload }, mergeCopyArrays);
  })
  .handleAction(getOverviewSpeedChartAC.success, (state, action) => {
    return _.mergeWith(_.cloneDeep(state), { ...action.payload }, mergeCopyArrays);
  })
  .handleAction(getOverviewImpressionsAvailabilityChartsAC.success, (state, action) => {
    return _.mergeWith(_.cloneDeep(state), { ...action.payload }, mergeCopyArrays);
  })
  .handleAction(
    [getOverviewProductionCardsAC.request, getOverviewAchievementsAC.request, setTimeframeAC],
    (state, action) => {
      if (action.payload.isSilent) {
        return state;
      }
      return INIT_STATE;
    }
  )
  .handleAction(getOverviewReportMainRequestAC.request, (state, action) => {
    if (action.payload.isSilent) {
      return { ...state };
    } else {
      return {
        ...state,
        Apollo: {
          ...state.Apollo,
          isOverviewMainRequestLoading: true,
          productionChart: undefined,
          speedChart: undefined,
          utilizationChart: undefined,
          productionTrend: undefined,
          totalProduction: undefined,
          productivity: undefined,
          productivityChange: undefined,
          productionRate: undefined,
          productionRateTrend: undefined,
        },
      };
    }
  })
  .handleAction(getOverviewReportMainRequestAC.success, (state, action) => {
    const {
      impressionsByHourMatrix,
      totalImpressionsMatrix,
      utilizationProductivityMatrix,
      productionRate,
      productivity,
      totalImpressions,
    } = action.payload;
    return {
      ...state,
      Apollo: {
        ...state.Apollo,
        productionChart: totalImpressionsMatrix.records,
        speedChart: impressionsByHourMatrix.records,
        utilizationChart: utilizationProductivityMatrix.records,
        productionTrend: totalImpressions.productionTrend,
        totalProduction: totalImpressions.totalProduction,
        productivity: productivity.productivity,
        productivityChange: productivity.productivityChange,
        productionRate: productionRate.productionRate,
        productionRateTrend: productionRate.productionRateTrend,
        loadingTime: productionRate.loadingTime,
        loadingTimeTrend: productionRate.loadingTimeTrend,
        isOverviewMainRequestLoading: false,
        timeOfLastMainResponse: new Date(),
      },
    };
  })
  .handleAction(getOverviewAvailabilityRequestAC.request, (state, action) => {
    if (action.payload.isSilent) {
      return { ...state };
    } else {
      return {
        ...state,
        Apollo: {
          ...state.Apollo,
          isOverviewAvailabilityRequestLoading: true,
          availability: undefined,
          averageAvailabilityTrend: undefined,
          errorDuration: undefined,
          errorDurationTrend: undefined,
          maintenanceDuration: undefined,
          maintenanceDurationTrend: undefined,
          availabilityChart: undefined,
        },
      };
    }
  })
  .handleAction(getOverviewAvailabilityRequestAC.success, (state, action) => {
    return {
      ...state,
      Apollo: {
        ...state.Apollo,
        isOverviewAvailabilityRequestLoading: false,
        availability: action.payload.availability.availability,
        availabilityTrend: action.payload.availability.averageAvailabilityTrend,
        errorDuration: action.payload.availability.errorDurationSeconds,
        errorDurationTrend: action.payload.availability.errorDurationTrend,
        maintenanceDuration: action.payload.availability.maintenanceDurationSeconds,
        maintenanceDurationTrend: action.payload.availability.maintenanceDurationTrend,
        availabilityChart: action.payload.availabilityMatrix.records,
        timeOfLastAvailabilityResponseResponse: new Date(),
      },
    };
  })
  //* this action from jobAnalysisActions and needed only to updated last response time
  //* it should be replaced with refactoring with timeOfLastAvailabilityResponseResponse to realTime reducer or somewhere to shared place
  .handleAction(jobAnalysisInkConsumptionKPIAC.success, (state, action) => {
    const systemGroup = Object.keys(action.payload)[0];
    return {
      ...state,
      [systemGroup]: { ...state[systemGroup], timeOfLastMainResponse: new Date() },
    };
  })
  .handleAction(getSystemAnalysisAC.success, (state, action) => {
    const systemGroup = action.payload.systemGroup;
    return {
      ...state,
      [systemGroup]: { ...state[systemGroup], timeOfLastMainResponse: new Date() },
    };
  })
  .handleAction(getAvailabilityCardAC.success, (state, action) => {
    const systemGroup = action.payload.systemGroup;
    return {
      ...state,
      [systemGroup]: { ...state[systemGroup], timeOfLastMainResponse: new Date() },
    };
  })
  .handleAction(getMaxKeepAliveRequestAC.request, (state, action) => {
    if (action.payload.isSilent) {
      return { ...state };
    }
    return {
      ...state,
      [action.payload.systemGroup]: {
        ...state[action.payload.systemGroup],
        maxKeepAlive: undefined,
        maxKeepAliveUtc: undefined,
      },
    };
  })
  .handleAction(getMaxKeepAliveRequestAC.success, (state, action) => {
    return {
      ...state,
      [action.payload.systemGroup]: {
        ...state[action.payload.systemGroup],
        maxKeepAlive: action.payload.maxKeepAlive,
        maxKeepAliveUtc: action.payload.maxKeepAliveUtc,
      },
    };
  })
  .handleAction(getMaxKeepAliveRequestAC.failure, (state, action) => {
    return {
      ...state,
      [action.payload.systemGroup]: {
        ...state[action.payload.systemGroup],
        maxKeepAlive: undefined,
        maxKeepAliveUtc: undefined,
      },
    };
  });

export default reducer;

const overviewSelector = (state: Store) => {
  return state.overview;
};
const overviewBySystemGroupSelector = createSelector(
  [overviewSelector, systemGroupSelector],
  (overview, systemGroup) => {
    return overview[systemGroup];
  }
);
export const overviewMaxKeepAliveSelector = createSelector(
  [overviewBySystemGroupSelector],
  overview => {
    return overview['maxKeepAlive'];
  }
);
export const overviewMaxKeepAliveUTCSelector = createSelector(
  [overviewBySystemGroupSelector],
  overview => {
    return overview['maxKeepAliveUtc'];
  }
);
export const overviewTimeOfLastMainResponseSelector = createSelector(
  [overviewBySystemGroupSelector],
  overview => {
    return overview['timeOfLastMainResponse'];
  }
);
export const overviewTimeOfLastAvailabilityResponseSelector = createSelector(
  [overviewBySystemGroupSelector],
  overview => {
    return overview['timeOfLastAvailabilityResponseResponse'];
  }
);
export const isOverviewMainRequestLoadingSelector = createSelector(
  [overviewBySystemGroupSelector],
  overview => {
    return overview['isOverviewMainRequestLoading'];
  }
);

export const overviewBySystemGroupAvailabilityTotalDurationReportedSelector = createSelector(
  [overviewBySystemGroupSelector],
  overview => {
    return overview['availabilityTotalDurationReported'];
  }
);

export const overviewBySystemGroupIsNotReportingPeriodSelector = createSelector(
  [overviewBySystemGroupSelector],
  overview => {
    return overview['isNotReportingPeriod'];
  }
);

export const overviewBySystemGroupYearlyPercentageSelector = createSelector(
  [overviewBySystemGroupSelector],
  overview => {
    return overview['yearlyPercentage'];
  }
);

export const overviewBySystemGroupYearlyGoalSelector = createSelector(
  [overviewBySystemGroupSelector],
  overview => {
    return overview['yearlyGoal'];
  }
);

export const overviewBySystemGroupYearlyCompletedSelector = createSelector(
  [overviewBySystemGroupSelector],
  overview => {
    return overview['yearlyCompleted'];
  }
);

export const overviewBySystemGroupWeeklyPercentageSelector = createSelector(
  [overviewBySystemGroupSelector],
  overview => {
    return overview['weeklyPercentage'];
  }
);

export const overviewBySystemGroupWeeklyGoalSelector = createSelector(
  [overviewBySystemGroupSelector],
  overview => {
    return overview['weeklyGoal'];
  }
);

export const overviewBySystemGroupWeeklyCompletedSelector = createSelector(
  [overviewBySystemGroupSelector],
  overview => {
    return overview['weeklyCompleted'];
  }
);

export const overviewBySystemGroupAvailabilitySelector = createSelector(
  [overviewBySystemGroupSelector],
  overview => {
    return overview['availability'];
  }
);
export const overviewBySystemGroupavailAbilityTrendSelector = createSelector(
  [overviewBySystemGroupSelector],
  overview => {
    return overview['availabilityTrend'];
  }
);

export const overviewBySystemGroupErrorDurationSelector = createSelector(
  [overviewBySystemGroupSelector],
  overview => {
    return overview['errorDuration'];
  }
);

export const overviewBySystemGroupDarkImpressionsPercentageSelector = createSelector(
  [overviewBySystemGroupSelector],
  overview => {
    return overview['darkImpressionsPercentage'];
  }
);

export const overviewBySystemGroupMaintenanceDurationSelector = createSelector(
  [overviewBySystemGroupSelector],
  overview => {
    return overview['maintenanceDuration'];
  }
);

export const overviewBySystemGroupLoadingTimeSelector = createSelector(
  [overviewBySystemGroupSelector],
  overview => {
    return overview['loadingTime'];
  }
);

export const overviewBySystemGroupProductionRateSelector = createSelector(
  [overviewBySystemGroupSelector],
  overview => {
    return overview['productionRate'];
  }
);

export const overviewBySystemGroupProductionRateTrendSelector = createSelector(
  [overviewBySystemGroupSelector],
  overview => {
    return overview['productionRateTrend'];
  }
);

export const overviewBySystemGroupProductionTrendSelector = createSelector(
  [overviewBySystemGroupSelector],
  overview => {
    return overview['productionTrend'];
  }
);

export const overviewBySystemGroupTotalProductionSelector = createSelector(
  [overviewBySystemGroupSelector],
  overview => {
    return overview['totalProduction'];
  }
);

export const overviewBySystemGroupDarkImpressionsTrendSelector = createSelector(
  [overviewBySystemGroupSelector],
  overview => {
    return overview['darkImpressionsTrend'];
  }
);

export const overviewBySystemGroupLoadingTimeTrendSelector = createSelector(
  [overviewBySystemGroupSelector],
  overview => {
    return overview['loadingTimeTrend'];
  }
);

export const overviewBySystemGroupMaintenanceDurationTrendSelector = createSelector(
  [overviewBySystemGroupSelector],
  overview => {
    return overview['maintenanceDurationTrend'];
  }
);

export const overviewBySystemGroupErrorDurationTrendSelector = createSelector(
  [overviewBySystemGroupSelector],
  overview => {
    return overview['errorDurationTrend'];
  }
);

export const overviewBySystemGroupTrendPreparationSelector = createSelector(
  [overviewBySystemGroupSelector],
  overview => {
    return overview['trendPreparation'];
  }
);

export const overviewBySystemGroupTrendUtilizationSelector = createSelector(
  [overviewBySystemGroupSelector],
  overview => {
    return overview['trendUtilization'];
  }
);

export const overviewBySystemGroupUtilizationSelector = createSelector(
  [overviewBySystemGroupSelector],
  overview => {
    return overview['utilization'];
  }
);
export const overviewBySystemGroupPreparationSelector = createSelector(
  [overviewBySystemGroupSelector],
  overview => {
    return overview['preparation'];
  }
);
export const overviewBySystemGroupProductivitySelector = createSelector(
  [overviewBySystemGroupSelector],
  overview => {
    return overview['productivity'];
  }
);

export const overviewBySystemGroupProductivityChangeSelector = createSelector(
  [overviewBySystemGroupSelector],
  overview => {
    return overview['productivityChange'];
  }
);

export const overviewBySystemGroupPrintingTimeSelector = createSelector(
  [overviewBySystemGroupSelector],
  overview => {
    return overview['printingTime'];
  }
);

export const overviewBySystemGroupPrintingTimeTrendSelector = createSelector(
  [overviewBySystemGroupSelector],
  overview => {
    return overview['printingTimeTrend'];
  }
);

export const overviewBySystemGroupProductivityUtilizationSelector = createSelector(
  [overviewBySystemGroupSelector],
  overview => {
    return overview['productivityUtilization'];
  }
);

export const overviewBySystemGroupProductivityUtilizationTrendSelector = createSelector(
  [overviewBySystemGroupSelector],
  overview => {
    return overview['productivityUtilizationTrend'];
  }
);

export const overviewBySystemGroupRecords = createSelector(
  [overviewBySystemGroupSelector],
  overviewBySystemGroup => {
    return overviewBySystemGroup['record'];
  }
);
export const overviewBySystemGroupProductionChart = createSelector(
  [overviewBySystemGroupSelector],
  overviewBySystemGroup => {
    return overviewBySystemGroup['productionChart'];
  }
);
export const overviewBySystemGroupUtilizationChart = createSelector(
  [overviewBySystemGroupSelector],
  overviewBySystemGroup => {
    return overviewBySystemGroup['utilizationChart'];
  }
);
export const overviewBySystemGroupSpeedChart = createSelector(
  [overviewBySystemGroupSelector],
  overviewBySystemGroup => {
    return overviewBySystemGroup['speedChart'];
  }
);
export const overviewBySystemGroupAvailabilityChart = createSelector(
  [overviewBySystemGroupSelector],
  overviewBySystemGroup => {
    return overviewBySystemGroup['availabilityChart'];
  }
);
