import { createReducer } from 'typesafe-actions';
import { Store, TvDashboardByType } from 'store/storeTypes';
import { getTvDashboardDataAC } from 'actions/tv_dashboardActions';
import { createSelector } from 'reselect';
import { systemGroupSelector } from './ui/systemGroupUiReducer';
import { singlePrinterSelection } from 'actions/reportFilterActions';

const INIT_STATE: TvDashboardByType = {
  Apollo: {
    currentShiftType: undefined,
    records: undefined,
    totalImpressions: undefined,
    targetImpressions: undefined,
    percentage: undefined,
    currentShiftStartTime: undefined,
    isLoading: true,
    error: '',
  },
  DTG: {
    currentShiftType: undefined,
    records: undefined,
    totalImpressions: undefined,
    targetImpressions: undefined,
    percentage: undefined,
    currentShiftStartTime: undefined,
    isLoading: true,
    error: '',
  },
  DTF: {
    currentShiftType: undefined,
    records: undefined,
    totalImpressions: undefined,
    targetImpressions: undefined,
    percentage: undefined,
    currentShiftStartTime: undefined,
    isLoading: true,
    error: '',
  },
};

const reducer = createReducer<TvDashboardByType, any>(INIT_STATE)
  .handleAction(singlePrinterSelection, (state, action) => {
    return INIT_STATE;
  })
  .handleAction(getTvDashboardDataAC.request, (state, action) => {
    const { systemGroup, isSilent } = action.payload;
    if (isSilent) {
      return {
        ...state,
      };
    }
    return {
      ...state,
      [systemGroup]: {
        ...state[systemGroup],
        isLoading: true,
        error: '',
      },
    };
  })
  .handleAction(getTvDashboardDataAC.success, (state, action) => {
    const {
      systemGroup,
      records,
      currentShiftType,
      totalImpressions,
      targetImpressions,
      percentage,
      currentShiftStartTime,
    } = action.payload;
    return {
      ...state,
      [systemGroup]: {
        ...state[systemGroup],
        records,
        currentShiftType,
        currentShiftStartTime,
        totalImpressions: totalImpressions || 0,
        targetImpressions: targetImpressions || 0,
        percentage: percentage || 0,
        isLoading: false,
        error: '',
      },
    };
  })
  .handleAction(getTvDashboardDataAC.failure, (state, action) => {
    const { systemGroup, error } = action.payload;
    return {
      ...state,
      [systemGroup]: {
        ...state[systemGroup],
        isLoading: false,
        error,
      },
    };
  });
export default reducer;

//*selectors
const tvDashbaordSelector = (state: Store) => {
  return state.tvDashboard;
};
export const tvDashboardBySystemGroupSelector = createSelector(
  [tvDashbaordSelector, systemGroupSelector],
  (tvDashboard, systemGroup) => {
    return tvDashboard[systemGroup];
  }
);
export const tvDashboardRecordsSelector = createSelector(
  [tvDashboardBySystemGroupSelector],
  tvDashboard => tvDashboard.records
);
export const tvDashboardIsLoadingSelector = createSelector(
  [tvDashboardBySystemGroupSelector],
  tvDashboard => tvDashboard.isLoading
);
export const tvDashboardErrorSelector = createSelector(
  [tvDashboardBySystemGroupSelector],
  tvDashboard => tvDashboard.error
);
export const tvDashboardTotalImpressionsSelector = createSelector(
  [tvDashboardBySystemGroupSelector],
  tvDashboard => tvDashboard.totalImpressions
);

export const tvDashboardTargetImpressionsSelector = createSelector(
  [tvDashboardBySystemGroupSelector],
  tvDashboard => tvDashboard.targetImpressions
);
export const tvDashboardPercentageSelector = createSelector(
  [tvDashboardBySystemGroupSelector],
  tvDashboard => tvDashboard.percentage
);
export const tvDashboarCurrentShiftStartTimeSelector = createSelector(
  [tvDashboardBySystemGroupSelector],
  tvDashboard => tvDashboard.currentShiftStartTime
);
export const tvDashboardShiftTypeSelector = createSelector(
  [tvDashboardBySystemGroupSelector],
  tvDashboard => tvDashboard.currentShiftType
);
