import React from 'react';
import { RowWrapper, LeftPart, CheckBox, NameWrapper, RightPart, NameSpan } from './SiteRow';
import { ReactComponent as CheckedIcon } from '../../assets/icons/CheckedIcon.svg';

import { Fade } from '@material-ui/core';
import { StyledTooltip } from 'globalStyle';
import {
  ReportFilterState,
  ReportsFilterDataByTypePartial,
  UserSystemGroupStrings,
} from 'store/storeTypes';
import { SinglePrinterSelectionPayload } from 'actions/reportFilterActions';

export type SystemRowProps = {
  name: string;
  siteId: string;
  systemNickName: string;
  modelId: string;
  filterState: ReportFilterState;
  filterData: ReportsFilterDataByTypePartial;
  selectedSystemGroup: UserSystemGroupStrings;
  isSignleSelectionMode: boolean;
  singlePrinterSelection: ({}: SinglePrinterSelectionPayload) => void;
  toggleFilterSerialRowSystemSelection?: ({}) => void;
};
const SystemRow = (props: SystemRowProps) => {
  const {
    name: serialId,
    siteId,
    systemNickName,
    modelId,
    filterState,
    filterData,
    selectedSystemGroup,
    isSignleSelectionMode,
    singlePrinterSelection,
    toggleFilterSerialRowSystemSelection,
  } = props;

  const onPrinterClick = () => {
    if (isSignleSelectionMode) {
      singlePrinterSelection({
        serialId,
        modelId,
        siteId,
        selectedSystemGroup,
      });
    } else {
      toggleFilterSerialRowSystemSelection({
        selectedSystemGroup,
        filterState,
        filterData,
        serialId,
        modelId,
        siteId,
      });
    }
  };
  const isThisSystemSelected = filterState.selectedSystemSerials.includes(serialId);
  return (
    <>
      <RowWrapper data-automationid={serialId}>
        <LeftPart onClick={onPrinterClick} style={{ marginLeft: '30px' }}>
          <CheckBox data-testid="system-row-checkbox" checked={isThisSystemSelected}>
            {isThisSystemSelected && <CheckedIcon fill="white" />}
          </CheckBox>
          <StyledTooltip
            disableHoverListener={!systemNickName} // disable tooltip if no nick name
            style={{ position: 'relative' }}
            title={`nick name : ${systemNickName}`}
            placement="top"
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 300 }}>
            <NameWrapper>
              <NameSpan data-testid="system-name">
                {serialId + ' '}
                {systemNickName && `${systemNickName}`}
              </NameSpan>
            </NameWrapper>
          </StyledTooltip>
        </LeftPart>
        <RightPart></RightPart>
      </RowWrapper>
    </>
  );
};
export default SystemRow;
