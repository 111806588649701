import React, { Suspense, useEffect, useRef } from 'react';
import { MainReportTypes, SubReportTypes } from 'models/ReportData';
import { PbiReportsPageProps } from './PbiReportsPageContainer';
import { getFilterHideEnv } from 'helpers/envGetters';
import { Spinner, SpinnerContainer } from 'components/Overview/Styles';
import PageContainer from 'components/_common/PageContainer';
import styled from 'styled-components';
import { isNeverReported } from 'helpers/empty-state';

import Slider from '../PbiReportsPage/Slider';
import Overview from 'components/Overview';
import PbiFilter from '../PbiFilter/PbiFilterContainer';
import EmptyState, { EmptyStateWrapper } from 'components/_common/EmptyState';
import { UserSystemGroup } from 'store/storeTypes';

const SystemAnalysis = React.lazy(() => import('../SystemAnalysis/SystemsAnalysisContainer'));
const MachineConfig = React.lazy(() => import('components/MachineConfig'));
const ServiceTicket = React.lazy(() => import('components/ServiceTicket'));
const SystemPage = React.lazy(() => import('components/SystemPage/SystemPageContainer'));
const JobAnalysis = React.lazy(() => import('components/JobAnalysis/JobAnalysisContainer'));
const TvDashboard = React.lazy(() => import('components/TvDashboard/TvDashboardContainer'));
const FILTER_HIDE = getFilterHideEnv();

export const IconPlaceHolder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const RepotPageWrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
`;

const PbiReportsPage = React.memo((props: PbiReportsPageProps) => {
  const {
    match,
    activeReport,
    timeFrame,
    day,
    isStateInitialized,
    filterData,
    isSelectedSystemsEverReported,
    isEverReportedLoaded,
    isMachineFilterOpen,
    screenWidth,
    screenHeight,
    pbiReportHeight,
    pbiReportWidth,
    selectedSystems,
    selectedSystemGroup,
    setPbiReportSize,
    setActiveReport,
    toggleMachineFilterMenu,
  } = props;

  const routerActiveReport = match.params.reportType as MainReportTypes;
  const routerActiveSubReport = match.params.subReportType as SubReportTypes;
  //*START to get access to real DOM element of PbiPage - needed to get width and height
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current) {
      const { width, height } = ref.current.getBoundingClientRect();
      setPbiReportSize({ height, width });
    }
  }, [screenHeight, screenWidth, pbiReportWidth, pbiReportHeight, ref.current]);
  //*END to get access to real DOM element of PbiPage - needed to get width and height

  useEffect(() => {
    if (routerActiveSubReport) {
      //*close machineFilter before render subreport if it is opened
      isMachineFilterOpen && toggleMachineFilterMenu();
      setActiveReport(routerActiveSubReport);
    } else if (routerActiveReport) {
      setActiveReport(routerActiveReport);
    } else {
      setActiveReport(null);
    }
  }, [setActiveReport, match.params.reportType, match.params.subReportType]);
  const reports = React.useMemo(() => {
    const mainReportNames: MainReportTypes[] = [];
    for (const key in MainReportTypes) {
      mainReportNames.push(MainReportTypes[key]);
    }
    const mainReportsMap = new Map<MainReportTypes | SubReportTypes, JSX.Element>();

    mainReportsMap.set(MainReportTypes.Overview, <Overview timeFrame={timeFrame} day={day} />);
    mainReportsMap.set(MainReportTypes.Systems, <SystemAnalysis timeFrame={timeFrame} day={day} />);
    mainReportsMap.set(MainReportTypes.Jobs, <JobAnalysis timeFrame={timeFrame}></JobAnalysis>);
    mainReportsMap.set(
      SubReportTypes.SystemPage,
      <SystemPage systemName={match.params.systemName} />
    );
    mainReportsMap.set(MainReportTypes.TvDashboard, <TvDashboard />);
    mainReportsMap.set(
      SubReportTypes.MachineConfig,
      <MachineConfig
        timeframe={timeFrame}
        day={day}
        systemName={match.params.systemName}></MachineConfig>
    );
    mainReportsMap.set(
      SubReportTypes.ServiceTicket,
      <ServiceTicket timeframe={timeFrame} day={day} systemName={match.params.systemName} />
    );

    return mainReportsMap;
  }, [day, timeFrame, match]);

  const isNotReported = isNeverReported(
    filterData,
    isSelectedSystemsEverReported,
    selectedSystemGroup,
    isEverReportedLoaded
  );
  return activeReport ? (
    <PageContainer
      data-id="Page-Container"
      ref={ref}
      style={{
        display: 'flex',
      }}>
      {!FILTER_HIDE && <PbiFilter />}

      {!isStateInitialized ? (
        <>
          <SpinnerContainer>{Spinner(103)}</SpinnerContainer>
        </>
      ) : (
        <RepotPageWrapper data-id="RepotPageWrapper">
          {isMachineFilterOpen && (
            <Slider pbiReportHeight={pbiReportHeight} screenWidth={screenWidth} />
          )}
          {/* //*next check is needed to show empty state if nothing was selected in apollo group and
          //*for other groups if nothing state isNeverReported */}
          {isNotReported ||
          (selectedSystemGroup === UserSystemGroup.Apollo && selectedSystems.length === 0) ? (
            <EmptyStateWrapper>
              <EmptyState
                icon={selectedSystems.length === 0 ? 'list' : 'empty-state'}
                message={selectedSystems.length === 0 ? 'Nothing Was Selected' : ''}
              />
            </EmptyStateWrapper>
          ) : (
            // <EmptyState isNotSelected={selectedSystems.length === 0} />

            <Suspense fallback={<SpinnerContainer>{Spinner()}</SpinnerContainer>}>
              {reports.get(activeReport)}
            </Suspense>
          )}
        </RepotPageWrapper>
      )}
    </PageContainer>
  ) : (
    <></>
  );
});

export default PbiReportsPage;
