import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import FilterSystemsPopUpMenu from './FilterSystemsPopUpMenu';
import { Store } from 'store/storeTypes';
import {
  toggleFilterRowSystemsSelection,
  toggleFilterModelRowSystemsSelection,
  toggleFilterSerialRowSystemSelection,
  toggleAllSystemsSelection,
  singlePrinterSelection,
} from '../../actions/reportFilterActions';

import {
  filterDataBySystemGroupSelector,
  filteredSitesSelector,
  filteredSystemSelector,
  filterStateBySystemGroupSelector,
  filterValueSelector,
  fitleredModelsSelector,
} from 'selectors/filterStateDataSelectors';
import { systemGroupSelector } from 'reducers/ui/systemGroupUiReducer';
import { activeReportSelector } from 'reducers/reports/filterDataReducer';

export interface OwnProps {
  pbiReportHeight?: number;
  isMobile?: boolean;
}

const mapStateToProps = (state: Store) => {
  return {
    selectedSystemGroup: systemGroupSelector(state),
    filterData: filterDataBySystemGroupSelector(state),
    filterState: filterStateBySystemGroupSelector(state),
    filterValue: filterValueSelector(state),
    filteredSite: filteredSitesSelector(state),
    filteredModel: fitleredModelsSelector(state),
    filteredSystem: filteredSystemSelector(state),
    activeReport: activeReportSelector(state),
  };
};
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      toggleFilterRowSystemsSelection,
      toggleFilterModelRowSystemsSelection,
      toggleFilterSerialRowSystemSelection,
      toggleAllSystemsSelection,
      singlePrinterSelection,
    },
    dispatch
  );

export type IOverviewProps = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(FilterSystemsPopUpMenu);
