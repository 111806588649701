import { Store } from 'store/storeTypes';
import { createReducer } from 'typesafe-actions';
import { createSelector } from 'reselect';
import { hidePbiFilterAC, revealPbiFilterAC } from 'actions/reportFilterActions';

const initState = { isPbiFilterHidden: false };

export default createReducer<any, any>(initState)
  .handleAction(hidePbiFilterAC, (state, action) => {
    return { ...state, isPbiFilterHidden: true };
  })
  .handleAction(revealPbiFilterAC, (state, action) => {
    return { ...state, isPbiFilterHidden: false };
  });

const uiSelector = (state: Store) => {
  return state['ui'];
};
export const isPbiFilterHiddenSelector = createSelector([uiSelector], ui => {
  return ui['pbiFilter'].isPbiFilterHidden;
});
