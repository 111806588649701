import React, { useEffect, useState } from 'react';
import { ReactComponent as CheckedIcon } from '../../assets/icons/CheckedIcon.svg';
import { ReactComponent as FilterUnfoldIcon } from '../../assets/icons/FilterUnfoldIcon.svg';
import { ReactComponent as HalfCheckedIcon } from '../../assets/icons/HalfCheckedIcon.svg';
import { ReactComponent as FilterFoldIcon } from '../../assets/icons/FilterFoldIcon.svg';
import { RowWrapper, LeftPart, NameWrapper, RightPart, NameSpan, CheckBox } from './SiteRow';
import SystemRow from './SystemRow';
import { SinglePrinterSelectionPayload } from 'actions/reportFilterActions';
import {
  ReportFilterState,
  ReportFilterData,
  ReportSystem,
  UserSystemGroupStrings,
  ReportModel,
} from 'store/storeTypes';
import { useTranslation } from 'react-i18next';
import { StyledTooltip } from 'globalStyle';

type ModelRowProps = {
  id: string;
  name: string;
  siteId: string;
  modelId: string;
  filterState: ReportFilterState;
  filterData: ReportFilterData;
  filteredSerials: ReportSystem[];
  filterValue?: string;
  filteredModels?: ReportModel[];
  selectedSystemGroup: UserSystemGroupStrings;
  isSignleSelectionMode: boolean;
  toggleFilterModelRowSystemsSelection: ({}) => void;
  toggleFilterSerialRowSystemSelection: ({}) => void;
  singlePrinterSelection: ({}: SinglePrinterSelectionPayload) => void;
};

const ModelRow = ({
  id,
  name,
  siteId,
  modelId,
  filterState,
  filterData,
  filteredSerials,
  filterValue,
  selectedSystemGroup,
  isSignleSelectionMode,
  toggleFilterModelRowSystemsSelection,
  toggleFilterSerialRowSystemSelection,
  singlePrinterSelection,
}: ModelRowProps) => {
  const { t } = useTranslation();
  const onClick = () => {
    if (!isSignleSelectionMode) {
      toggleFilterModelRowSystemsSelection({
        selectedSystemGroup,
        modelId: id,
        siteId: siteId,
        filterData,
        filterState,
      });
    }
  };
  const [isFolded, setFolded] = useState(true);
  useEffect(() => {
    filterValue ? setFolded(false) : setFolded(true);
  }, [filterValue]);
  const isThisModelSelected = filterState.selectedModelIds.includes(id);

  const allSerialsOfThisSite = filterData.systems.filter(system => system.siteId === siteId);
  const allSerialsOfThisModel = allSerialsOfThisSite.filter(system => system.modelId === modelId);

  const getFilteredSerials = (fSerials, allSerialsOfModel) => {
    if (fSerials === null) {
      return allSerialsOfModel;
    }
    const FILTERED_SERIALS_COUNT = fSerials.length;
    const ALL_SERIALS_MODEL_COUNT = allSerialsOfModel.length;
    if (FILTERED_SERIALS_COUNT < ALL_SERIALS_MODEL_COUNT) {
      return fSerials;
    } else {
      const filtered = [];
      fSerials.forEach(printer => {
        allSerialsOfModel.forEach(currentPrinter => {
          if (printer.name === currentPrinter.name) {
            filtered.push(currentPrinter);
          }
        });
      });
      return filtered;
    }
  };

  const serialsToRender = getFilteredSerials(filteredSerials, allSerialsOfThisModel);
  serialsToRender &&
    serialsToRender.sort(
      (printer1, printer2) =>
        +printer1.name.split('-').join('') - +printer2.name.split('-').join('')
    );
  const getCheckedIcon = (totalCount: number, selectedCount: number) => {
    if (totalCount === selectedCount) {
      return <CheckedIcon fill="white" />;
    }
    if (selectedCount > 0 && selectedCount < totalCount) {
      return <HalfCheckedIcon fill="white" />;
    }
  };
  const modelSystemSerialIds = filterData.systems
    .filter(system => system.modelId === id)
    .map(system => system.name);
  const modelSelectedSystemSerialIds = filterState.selectedSystemSerials.filter(selectedSerial =>
    modelSystemSerialIds.includes(selectedSerial)
  );
  const CheckedIconJSX = getCheckedIcon(
    modelSystemSerialIds.length,
    modelSelectedSystemSerialIds.length
  );

  return (
    <>
      <StyledTooltip
        disableHoverListener={!isSignleSelectionMode}
        title={t('tooltip.xmr.singleSelection')}>
        <RowWrapper data-automationid={name}>
          <LeftPart onClick={onClick} style={{ marginLeft: '15px' }}>
            <CheckBox data-testid="model-check-box" checked={isThisModelSelected}>
              {isThisModelSelected && CheckedIconJSX}
            </CheckBox>
            <NameWrapper>
              <NameSpan data-testid="model-name">{name}</NameSpan>
            </NameWrapper>
          </LeftPart>
          <RightPart onClick={() => setFolded(!isFolded)}>
            {isFolded ? <FilterUnfoldIcon data-testid="icon" /> : <FilterFoldIcon />}
          </RightPart>
        </RowWrapper>
      </StyledTooltip>
      {isFolded ? (
        <></>
      ) : (
        <>
          {serialsToRender.map(system => {
            return (
              <SystemRow
                key={system.id}
                {...system}
                filterState={filterState}
                filterData={filterData}
                selectedSystemGroup={selectedSystemGroup}
                siteId={siteId}
                modelId={id}
                isSignleSelectionMode={isSignleSelectionMode}
                singlePrinterSelection={singlePrinterSelection}
                toggleFilterSerialRowSystemSelection={toggleFilterSerialRowSystemSelection}
              />
            );
          })}
        </>
      )}
    </>
  );
};
export default ModelRow;
