import React, { useEffect, Fragment, memo } from 'react';
import { Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet';
import {
  userManagementPath,
  goalsPath,
  settingsPath,
  apiManagementPath,
  schedulerPath,
} from 'contracts/constants';
import AccountManagement from '../AccountManagement/AccountManagementContainer';
import ApiManagement from '../ApiManagement/ApiManagementContainer';
import Goals from '../Goals/GoalsContainer';
import PbiReportsPage from '../PbiReportsPage/PbiReportsPageContainer';
import ShellHeader from './ShellHeaderContainer';
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';
import { PermissionAuth0 } from 'models/Permissions';
import CookiesPrompt from '../_common/CookiesPrompt';
import { ShellProps } from './ShellContainer';
import { userRolesToPermissions } from 'helpers/conversions';
import { getAppUserAgreementVersionEnv } from 'helpers/envGetters';
import { TermsModal } from 'components/TermsModal/TermsModal';
import Settings from 'components/Settings/SettingsContainer';
import config from 'config/config';
import { KonnectAnalytics } from '../../KonnectAnalytics/konnectAnalytics';
import LinearProgress from 'components/_common/LinearProgress';
import Scheduler from '../Scheduler/SchedulerContainer';
import _ from 'lodash';
import { useDebouncedCallback } from 'use-debounce/lib';

const AGREEMENT_VERSION = getAppUserAgreementVersionEnv();

const ShellContainer = styled.div`
  height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  max-height: 100%;
  width: 100%;
  overflow: auto;
  @media (max-width: 380px) {
    padding: 0;
  }
  scrollbar-width: thin;
  scrollbar-color: #c1baba;

  &::-webkit-scrollbar-track {
    background: #e3e0e0;
    border-radius: 10px;
  }
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  &::-webkit-scrollbar-thumb {
    background: #c1baba;
    border-radius: 10px;
  }
`;

const Shell = memo((props: ShellProps) => {
  const {
    authService,
    userAgreementVersion,
    userAgreement,
    selectedSystemGroup,
    importedTennantId,
    tennantName,
    accountType,
    office,
    userSystemGroup,
    setAppUserParams,
    updateLogoutTimer,
    setAppUserAgreementVersion,
    setAppUserAgreement,
    setUserEmail,
    setUserLastName,
    setUserFirstName,
    getPortalApiUrl,
    getAppUserSettings,
    setScreenSize,
  } = props;
  const { t, i18n } = useTranslation();
  const updateDimensions = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    setScreenSize({ width, height });
  };

  const [debouncedUpdateDimensions] = useDebouncedCallback(e => {
    //@ts-ignore
    const { innerHeight, innerWidth } = e.target;
    setScreenSize({ width: innerWidth, height: innerHeight });
  }, 400);
  // Get user settings
  useEffect(() => {
    updateDimensions();
    getAppUserSettings();
  }, [getAppUserSettings]);

  // analytics data sending
  useEffect(() => {
    if (authService.isAuthenticated()) {
      const { idTokenPayload } = authService;
      const isSuperUser = idTokenPayload[`${config.AUTH0_CLAIMS_NAMESPACE}is_super_user`];
      //*in case of read only role we have email in nickName field in case of other roles we have it in name field
      const email = isSuperUser ? idTokenPayload.nickname : idTokenPayload.name;
      if (
        idTokenPayload &&
        idTokenPayload.sub &&
        importedTennantId &&
        tennantName &&
        userSystemGroup &&
        accountType &&
        office
      ) {
        KonnectAnalytics.login({
          email: email,
          importedTennantId,
          tennantName,
          accountType,
          office,
          userSystemGroup,
          isSuperUser: isSuperUser,
          config: config,
        });
      }
    }
  }, [tennantName, importedTennantId, userSystemGroup, accountType, userSystemGroup]);
  useEffect(() => {
    if (authService.isAuthenticated()) {
      const { idTokenPayload } = authService;
      // Get current user data: ID from token & user_picture url from API
      if (idTokenPayload && idTokenPayload.sub) {
        setAppUserParams({
          id: idTokenPayload.sub,
          reportTypes: [],
          profilePictureUrl:
            idTokenPayload[`${config.AUTH0_CLAIMS_NAMESPACE}picture`] || idTokenPayload.picture,
          permissions: userRolesToPermissions(
            idTokenPayload[`${config.AUTH0_CLAIMS_NAMESPACE}roles`] || []
          ),
          userAgreementVersion:
            // JSON.parse(localStorage.getItem(`${idTokenPayload.sub}agreement_version`)!) ||
            idTokenPayload[`${config.AUTH0_CLAIMS_NAMESPACE}agreement_version`],
          userAgreement:
            // JSON.parse(localStorage.getItem(`${idTokenPayload.sub}agreement_approved`)!) ||
            idTokenPayload[`${config.AUTH0_CLAIMS_NAMESPACE}agreement_approved`],
        });
        setUserEmail({ email: idTokenPayload.name });
        setUserFirstName({ firstName: idTokenPayload.given_name });
        setUserLastName({ lastName: idTokenPayload.family_name });
        getPortalApiUrl();
      }

      // Set current locale according to token locale
      const localePropName = `${config.AUTH0_CLAIMS_NAMESPACE}locale`;
      const locale = idTokenPayload && idTokenPayload[localePropName];
      if (locale && i18n.language !== locale) i18n.changeLanguage(locale);
    }
  }, [authService, setAppUserParams, i18n, getPortalApiUrl]);

  useEffect(() => {
    document.addEventListener('click', () => updateLogoutTimer());

    window.addEventListener('resize', e => {
      debouncedUpdateDimensions(e);
    });
    document.addEventListener('visibilitychange', function() {
      if (document.hidden) {
        KonnectAnalytics.finishSession('EndSession');
      } else {
        KonnectAnalytics.startSession('StartSession', { reason: 'user is back on tab' });
      }
    });
    return () => window.removeEventListener('resize', updateDimensions);
  }, [updateLogoutTimer]);

  const onSetAppUserAgreementVersion = () => {
    return setAppUserAgreementVersion(AGREEMENT_VERSION);
  };

  const onSetAppUserAgreement = (isApproved: boolean) => {
    setAppUserAgreement(isApproved, AGREEMENT_VERSION);
  };

  const { idTokenPayload } = authService;
  const isSuperUser = idTokenPayload[`${config.AUTH0_CLAIMS_NAMESPACE}is_super_user`];

  return selectedSystemGroup !== '' ? (
    <ShellContainer>
      <Helmet title={t('title')} />

      <ShellHeader />

      <Fragment>
        {!userAgreement && !isSuperUser && <TermsModal action={onSetAppUserAgreement} />}
        <ContentContainer>
          <Route component={PbiReportsPage} />

          <ProtectedRoute
            exact
            allowedRoles={[PermissionAuth0.Admin]}
            path={userManagementPath}
            component={AccountManagement}
          />

          <ProtectedRoute
            exact
            allowedRoles={[PermissionAuth0.GoalsSetter]}
            path={goalsPath}
            component={Goals}
          />

          <ProtectedRoute
            exact
            allowedRoles={[PermissionAuth0.Certificate]}
            path={apiManagementPath}
            component={ApiManagement}
          />

          <ProtectedRoute exact path={schedulerPath} component={Scheduler} />
          <ProtectedRoute exact path={settingsPath} component={Settings} />
        </ContentContainer>

        {!(userAgreement && userAgreementVersion === String(AGREEMENT_VERSION)) && !isSuperUser && (
          <CookiesPrompt action={onSetAppUserAgreementVersion} />
        )}
      </Fragment>
    </ShellContainer>
  ) : (
    <LinearProgress></LinearProgress>
  );
});

export default Shell;
