import { isAfter, parse } from 'date-fns';

export const getMaxValue = data => {
  let maxValue = -Infinity;
  data.forEach(record => {
    const avg = record.avg;
    const xValue = record.x.value;
    const minMax = Math.max(...record.minMax);
    maxValue = Math.max(maxValue, avg, xValue, minMax);
  });
  return maxValue;
};
//* Add to data gray zone based on currentShiftStartTime
export const updateData = (data, currentShiftStartTime, maxValue) => {
  const timeLimit = new Date(currentShiftStartTime);
  const indexOfNewDay = data.findIndex(record => record.time === '0:00');
  return data.map((record, index) => {
    const recordTime = parse(record.time, 'HH:mm', new Date()); // parse each record to time
    // if index < indexOfNewDay than we have to substract 1 day from recordTime variable
    if (index < indexOfNewDay) {
      recordTime.setDate(recordTime.getDate() - 1);
    }
    if (isAfter(recordTime, timeLimit)) {
      return { ...record, grayZone: [0, 0] }; // add gray zone with : [0, 0]
    } else {
      return { ...record, grayZone: [0, maxValue] }; // add gray zone with :  [0, maxValue]
    }
  });
};
export const convertServerResponseToChartData = data => {
  return data.map(record => {
    const date = new Date(record.intervalStart);
    const hours = date.getHours();
    const minutes = ('0' + date.getMinutes()).slice(-2);
    return {
      time: `${hours}:${minutes}`,

      avg: record.xAvg,
      x: {
        value: record.x,
        isPurple: !!record.xRule1,
      },
      minMax: [record.xMin, record.xMax],
    };
  });
};
export const getFirstSystem = ({ filterData, selectedSystemGroup }) => {
  const firstSite = filterData[selectedSystemGroup].sites[0];
  const firstSiteSystems = filterData[selectedSystemGroup].systems
    .filter(system => system.siteId === firstSite.id)
    .sort((a, b) => a.name.localeCompare(b.name));
  return firstSiteSystems[0];
};
