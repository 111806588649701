import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { PbiFilterTimeframeProps } from './PbiFilterTimeframeContainer';
import { MainReportTypes, REPORTS_CONFIG, SubReportTypes, TimeFrame } from 'models/ReportData';
import MuiButton from '@material-ui/core/Button';

import { capitalize } from 'lodash';
import { isNeverReported } from 'helpers/empty-state';
import { UserSystemGroup } from 'store/storeTypes';
import DesktopPbiFilterTimeFrame from './DesktopPbiFilterTimeFrame';
import MobilePbiFilterTimeFrame from './MobilePbiFilterTimeFrame';
import { getRequestForTimeFrame } from 'helpers/date';

export const LastUpdateButton = styled(MuiButton)`
  color: #2ea1f8;
  border-color: #2ea1f8;
  padding: 0 10px;
  height: 31px;
  line-height: 1;
  font-weight: 400;
`;

const PbiFilterTimeframe = (props: PbiFilterTimeframeProps) => {
  const {
    activeReport,
    filterState,
    filterData,
    userSettings,
    selectedSystemGroup,
    isSelectedSystemsEverReported,
    downloadInkConsumptionPerJobError,
    isDownloadInkConsumptionPerJobLoading,
    isDownloadInkConsumptionPerJobLoaded,
    allUserSystems,
    isMobile,
    timeframe,
    jobAnalysisBySystemGroupIsValidToDownloadInkConsuptionPerJobMessage,
    jobAnalysisBySystemGroupIsValidToDownloadInkConsuptionPerJobIsLoading,
    selectedDate,
    selectedSerialsIds,
    isEverReportedLoaded,
    customTimeframeProperties,
    isReadOnlyRole,
    screenWidth,
    isInkConsumptionPerJobValidToDownload,
    downloadInkConsumptionPerJobReport,
    setTimeframe,
    setActiveDate,
    closeCalendarModal,
  } = props;
  const values = REPORTS_CONFIG[selectedSystemGroup]![activeReport].timeFrames;

  const items = activeReport === 'machineConfig' ? ['Day'] : values.map(value => capitalize(value));

  useEffect(() => {
    if (activeReport === MainReportTypes.Jobs) {
      const filterByPage = getRequestForTimeFrame(
        selectedDate,
        timeframe,
        customTimeframeProperties
      );
      filterByPage['systems'] =
        selectedSerialsIds.length === allUserSystems.length ? [] : selectedSerialsIds;
      filterByPage['SystemGroupType'] = selectedSystemGroup;

      selectedSystemGroup !== UserSystemGroup.Apollo &&
        isInkConsumptionPerJobValidToDownload(filterByPage);
    }
  }, [activeReport, timeframe, selectedSystemGroup, selectedDate, selectedSerialsIds]);

  const isShowSystemGroupFilter =
    userSettings.data.userSystemGroup?.length >= 2 &&
    !location.pathname.includes(SubReportTypes.SystemPage) &&
    !location.pathname.includes(SubReportTypes.MachineConfig);
  const isShowInkPerJobButton =
    selectedSystemGroup !== UserSystemGroup.Apollo &&
    location.pathname.includes(MainReportTypes.Jobs) &&
    filterState.selectedSystemSerials.length !== 0;

  const handlePageChange = (e: React.SyntheticEvent) => {
    let selectedtimeframe = (e.target as HTMLSelectElement).value as TimeFrame;
    if (!selectedtimeframe) {
      selectedtimeframe = (e.currentTarget as HTMLButtonElement).value as TimeFrame;
    }
    setTimeframe(selectedtimeframe, filterData.startDate);
  };
  const handleLastUpdateClick = () => {
    setActiveDate(filterData.endDate || new Date());
  };
  const isEmptyState = isNeverReported(
    filterData,
    isSelectedSystemsEverReported,
    selectedSystemGroup,
    isEverReportedLoaded
  );
  return isMobile ? (
    <MobilePbiFilterTimeFrame
      selectedTim
      activeReport={activeReport}
      timeframe={timeframe}
      items={items}
      closeCalendarModal={closeCalendarModal}
      setTimeframe={setTimeframe}
      startDate={filterData.startDate}
    />
  ) : (
    <DesktopPbiFilterTimeFrame
      screenWidth={screenWidth}
      activeReport={activeReport}
      isEmptyState={isEmptyState}
      isShowInkPerJobButton={isShowInkPerJobButton}
      isShowSystemGroupFilter={isShowSystemGroupFilter}
      isDownloadInkConsumptionPerJobLoaded={isDownloadInkConsumptionPerJobLoaded}
      isDownloadInkConsumptionPerJobLoading={isDownloadInkConsumptionPerJobLoading}
      filterState={filterState}
      jobAnalysisBySystemGroupIsValidToDownloadInkConsuptionPerJobIsLoading={
        jobAnalysisBySystemGroupIsValidToDownloadInkConsuptionPerJobIsLoading
      }
      jobAnalysisBySystemGroupIsValidToDownloadInkConsuptionPerJobMessage={
        jobAnalysisBySystemGroupIsValidToDownloadInkConsuptionPerJobMessage
      }
      downloadInkConsumptionPerJobError={downloadInkConsumptionPerJobError}
      allUserSystems={allUserSystems}
      selectedSystemGroup={selectedSystemGroup}
      timeframe={timeframe}
      items={items}
      values={values}
      filterData={filterData}
      customTimeframeProperties={customTimeframeProperties}
      selectedSerialsIds={selectedSerialsIds}
      selectedDate={selectedDate}
      setTimeframe={setTimeframe}
      handleLastUpdateClick={handleLastUpdateClick}
      handlePageChange={handlePageChange}
      downloadInkConsumptionPerJobReport={downloadInkConsumptionPerJobReport}
    />
  );
};

export default PbiFilterTimeframe;
