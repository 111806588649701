import { createSelector } from 'reselect';
import { createReducer } from 'typesafe-actions';
import _, { update } from 'lodash';

import {
  clearCardSelection,
  clearDtfCardsSelection,
  clearSelectionSizeCards,
  getModeSummaryCardsAC,
  highlightDTFCard,
  selectApolloCard,
  selectDTFCard,
  setDtfPageSelectorToDefaulBySizeState,
  toggleSelectionSizeCard,
  toggleSelectionSizeGroupCards,
} from './../actions/jobSummaryActions';
import { getJobSummaryCardsAC } from 'actions/jobSummaryActions';
import {
  ApolloCard,
  DtfPageSelector,
  JobSummaryStateTypes,
  ModeDTFTypeCards,
  SizeDTGCardType,
  Store,
  UserSystemGroup,
} from 'store/storeTypes';
import { jobAnalysisChartCardsAC, jobAnalysisChartsAC } from 'actions/jobAnalysisActions';
import { systemGroupSelector } from './ui/systemGroupUiReducer';
import { getDataError, getErrorMessage, updatedWithSelectionState } from 'helpers/jobAnalysisUtils';
import {
  setActiveDateAC,
  setTimeframeAC,
  toggleAllSystemsSelection,
  toggleFilterModelRowSystemsSelection,
  toggleFilterRowSystemsSelection,
  toggleFilterSerialRowSystemSelection,
  unselectSerialsByModel,
} from 'actions/reportFilterActions';

const INIT_STATE: JobSummaryStateTypes = {
  loading: false,
  isApolloLoaded: false,
  isApolloLoading: true,
  isDTGLoading: false,
  isDTGLoaded: false,
  isDTFLoading: false,
  isDTFLoaded: false,
  isPartialUnifiedData: false,
  isNoUnifiedData: false,
  error: '',
  DTG: new Array<SizeDTGCardType>(),
  Apollo: new Array<ApolloCard>(),
  dtfPageSelector: DtfPageSelector.BySize,
  DTF: new Array<ModeDTFTypeCards>(),
  isDtfResolutionGroupSelected: false,
};
const reducer = createReducer<JobSummaryStateTypes, any>(INIT_STATE)
  // need to reset state when timeframe or date is changed
  .handleAction(
    [
      setTimeframeAC,
      setActiveDateAC,
      unselectSerialsByModel,
      toggleAllSystemsSelection,
      toggleFilterSerialRowSystemSelection,
      toggleFilterModelRowSystemsSelection,
      toggleFilterRowSystemsSelection,
    ],
    (state, action) => {
      return INIT_STATE;
    }
  )
  //just to make default state is loading to false.
  .handleAction([jobAnalysisChartCardsAC.success, jobAnalysisChartsAC.success], (state, action) => {
    return { ...state, isDTFLoaded: false, isDTGLoaded: false };
  })

  //DTF actions
  .handleAction(getModeSummaryCardsAC.request, (state, action) => {
    return { ...state, isDTFLoading: true, isDTFLoaded: false };
  })
  .handleAction(getModeSummaryCardsAC.success, (state, action) => {
    return {
      ...state,
      isDTFLoading: false,
      isDTFLoaded: true,
      dtfPageSelector: DtfPageSelector.BySize,
      DTF: action.payload.jobSummaryData,
    };
  })
  .handleAction(getModeSummaryCardsAC.failure, (state, error) => {
    return {
      ...state,
      error: 'getting mode  summary data failed',
      isDTFLoading: false,
      isDTFLoaded: false,
    };
  })
  .handleAction(highlightDTFCard, (state, action) => {
    const { mode, resolution } = action.payload;
    if (!state.DTF) {
      return { ...state };
    }
    const oldCard: ModeDTFTypeCards | undefined = state.DTF.find(
      (card: { mode: number; modeResolution: string }) =>
        card.mode === mode && card.modeResolution === resolution
    );
    if (!oldCard) {
      return { ...state };
    }
    const newCard: ModeDTFTypeCards = { ...oldCard, selected: !oldCard.selected };

    const filteredDTFArray = [
      ...state.DTF.filter(
        (card: { mode: number; modeResolution: string }) =>
          card.modeResolution !== resolution || card.mode !== mode
      ),
    ];

    return {
      ...state,
      DTF: [...filteredDTFArray, newCard],
      dtfLoaded: true,
      isDTFLoaded: true,
    };
  })
  .handleAction(selectDTFCard, (state, action) => {
    return {
      ...state,
      dtfPageSelector: action.payload.cardHeader,
      dtfGroupPercent: action.payload.percentOfAllJob,
    };
  })

  .handleAction(clearDtfCardsSelection, (state, action) => {
    const newDtfCarsArray = state.DTF?.map(card => {
      return { ...card, selected: false };
    });
    return _.merge(_.cloneDeep(state), {
      DTF: newDtfCarsArray,
      isDTFLoaded: true,
    });
  })
  .handleAction(setDtfPageSelectorToDefaulBySizeState, (state, action) => {
    return {
      ...state,
      dtfPageSelector: DtfPageSelector.BySize,
    };
  })

  .handleAction(getJobSummaryCardsAC.request, (state, action) => {
    if (action.payload.isRealTime) {
      return { ...state };
    }
    return {
      ...state,
      isApolloLoading: true,
      isApolloLoaded: false,
      isDTGLoading: true,
      isDTGLoaded: false,
      error: '',
      dtfPageSelector: DtfPageSelector.BySize,
    };
  })
  .handleAction(getJobSummaryCardsAC.success, (state, action) => {
    //* jobSummaryDataShort name for apollo , jobSummaryData - name for dtg
    const fieldName =
      action.payload.systemGroup === UserSystemGroup.DTG ? 'jobSummaryData' : 'jobSummaryDataShort';
    const systemGroup = action.payload.systemGroup;
    //* need to keep previous selection state
    const oldCards = state[systemGroup];
    const updatedNewCards = updatedWithSelectionState(
      oldCards,
      action.payload[fieldName],
      action.payload.systemGroup
    );
    //*there could be 2 cases when we have to show warning or error message
    //*1st case  When (impression S+ impression M+Impression L )= 0  and total impression !=0   display only  something like  “There is no available data”
    //*2nd case  When (total impression != impression S+ impression M+Impression L), then present the KPI but add  display like something like “ size data is not available for part of the selected period”
    const { isNoUnifiedData, isPartialUnifiedData } = getDataError(
      updatedNewCards,
      action.payload.systemGroup
    );
    const errorMessage = getErrorMessage({ isNoUnifiedData, isPartialUnifiedData });
    return {
      ...state,
      isApolloLoading: false,
      isApolloLoaded: true,
      isDTGLoading: false,
      isDTGLoaded: true,
      [systemGroup]: updatedNewCards,
      dtfPageSelector: DtfPageSelector.BySize,
      error: errorMessage,
    };
  })
  .handleAction(selectApolloCard, (state, action) => {
    const { selectedSystemGroup, sizeCategory } = action.payload;
    const updatedCard = state[selectedSystemGroup].find(card => card.sizeCategory === sizeCategory);
    // if card has zero value user can not select it
    if (updatedCard.numberOfImpressions === 0) {
      return { ...state };
    }
    const updatedState = state[selectedSystemGroup].map(card => {
      if (card.sizeCategory === sizeCategory) {
        return { ...card, isSelected: !card.isSelected };
      }
      return card;
    });
    return { ...state, [selectedSystemGroup]: updatedState };
  })
  .handleAction(clearCardSelection, (state, action) => {
    const { selectedSystemGroup } = action.payload;
    const updatedState = state[selectedSystemGroup].map(card => {
      return { ...card, isSelected: false };
    });
    return { ...state, [selectedSystemGroup]: updatedState };
  })

  .handleAction(getJobSummaryCardsAC.failure, (state, error) => {
    return {
      ...state,
      isApolloLoading: false,
      isApolloLoaded: false,
      isDTGLoading: false,
      isDTGLoaded: false,
      error,
    };
  })
  .handleAction(toggleSelectionSizeCard, (state, action) => {
    const { id, selectedSystemGroup } = action.payload;
    if (!state[selectedSystemGroup]) {
      return { ...state };
    }
    const oldCard = state[selectedSystemGroup].find(card => card.id === id);
    if (!oldCard || !oldCard.production) {
      return { ...state };
    }

    const newCard = { ...oldCard, selected: !oldCard.selected };

    const filteredArray = [...state[selectedSystemGroup].filter(card => card.id !== id)];
    return {
      ...state,
      [selectedSystemGroup]: [...filteredArray, newCard],
      isDTGLoaded: true,
    };
  })
  .handleAction(toggleSelectionSizeGroupCards, (state, action) => {
    const { nameSet, selectedSystemGroup } = action.payload;
    const filter = nameSet.toLowerCase();
    if (!state[selectedSystemGroup]) {
      return { ...state };
    }
    const selectedCards = [
      ...state[selectedSystemGroup].filter(card => card.sizeString === filter),
    ];
    const [card1, card2] = selectedCards;
    const isAtLeastOneCardSelected = card1.selected || card2.selected;
    const filteredDTG = [
      ...state[selectedSystemGroup].filter(card => card.id !== card1.id && card.id !== card2.id),
    ];
    if (isAtLeastOneCardSelected) {
      const [newCard1, newCard2] = createNewCardGroupChoosenSizeByParam(false, selectedCards);

      const newSelectedCards = new Array();
      newCard1.production > 0 ? newSelectedCards.push(newCard1) : newSelectedCards.push(card1);
      newCard2.production > 0 ? newSelectedCards.push(newCard2) : newSelectedCards.push(card2);
      return { ...state, DTG: [...filteredDTG, ...newSelectedCards] };
    }
    const [newCard1, newCard2] = createNewCardGroupChoosenSizeByParam(true, selectedCards);

    const newSelectedCards = [];
    newCard1.production > 0 ? newSelectedCards.push(newCard1) : newSelectedCards.push(card1);
    newCard2.production > 0 ? newSelectedCards.push(newCard2) : newSelectedCards.push(card2);
    return { ...state, [selectedSystemGroup]: [...filteredDTG, ...newSelectedCards] };
  })
  .handleAction(clearSelectionSizeCards, (state, action) => {
    const newDtgCarsArray: Array<SizeDTGCardType> = [];
    state.DTG?.forEach(card => {
      newDtgCarsArray.push({ ...card, selected: false });
    });
    return _.merge(_.cloneDeep(state), { DTG: newDtgCarsArray, isDTGLoaded: true });
  });
export default reducer;
//helpers  for this reducer
export const createNewCardGroupChoosenSizeByParam = (
  newSelectedState: boolean,
  sourceArr: Array<SizeDTGCardType>
): Array<SizeDTGCardType> => {
  const [card1, card2] = sourceArr;
  const newCard1 = { ...card1, selected: newSelectedState };
  const newCard2 = { ...card2, selected: newSelectedState };
  return [newCard1, newCard2];
};
//*selectors
export const jobSummarySelector = (state: Store) => {
  return state.jobSummary;
};

export const dtgCardsSelector = createSelector([jobSummarySelector], jobSummary => {
  return jobSummary['DTG'];
});
export const apolloCardsSelector = createSelector([jobSummarySelector], jobSummary => {
  return jobSummary['Apollo'];
});
export const selectedApolloCardsSelector = createSelector([apolloCardsSelector], apolloCards => {
  return apolloCards.filter(card => card.isSelected);
});
export const dtfCardsSelector = createSelector([jobSummarySelector], jobSummary => {
  return jobSummary['DTF'];
});
export const jobSummaryCardsBySystemGroupSelector = createSelector(
  [jobSummarySelector, systemGroupSelector],
  (jobSummary, systemGroup) => {
    return jobSummary[systemGroup];
  }
);
export const JobSummaryLoadingSelector = createSelector([jobSummarySelector], jobSummary => {
  return jobSummary['loading'];
});

export const isDTFLoadedSelector = createSelector([jobSummarySelector], jobSummary => {
  return jobSummary['isDTFLoaded'];
});
export const isDTFLoadingSelector = createSelector([jobSummarySelector], jobSummary => {
  return jobSummary['isDTFLoading'];
});
export const isJobSummaryComponentLoadingSelectorByAnyGroup = createSelector(
  [jobSummarySelector],
  jobSummary => {
    return (
      jobSummary['isDTFLoading'] || jobSummary['isDTGLoading'] || jobSummary['isApolloLoading']
    );
  }
);
export const isDTGLoadedSelector = createSelector([jobSummarySelector], jobSummary => {
  return jobSummary['isDTGLoaded'];
});
export const isDTGLoadingSelector = createSelector([jobSummarySelector], jobSummary => {
  return jobSummary['isDTGLoading'];
});
export const dtfPageSelector = createSelector([jobSummarySelector], jobSummary => {
  return jobSummary['dtfPageSelector'];
});
export const isNoUnifiedDataSelector = createSelector([jobSummarySelector], jobSummary => {
  return jobSummary['isNoUnifiedData'];
});
export const isPartialUnifiedDataSelector = createSelector([jobSummarySelector], jobSummary => {
  return jobSummary['isPartialUnifiedData'];
});
export const errorSelector = createSelector([jobSummarySelector], jobSummary => {
  return jobSummary['error'];
});
export const jobSummaryBySystemGroup = createSelector(
  [jobSummarySelector, systemGroupSelector],
  (jobSummary, systemGroup) => {
    return jobSummary[systemGroup];
  }
);
export const jobSummarySelectedLengthSelector = createSelector(
  [jobSummaryBySystemGroup],
  jobSummary => {
    return jobSummary?.filter(card => card.selected).length;
  }
);
