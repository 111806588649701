import { ConfigNamesEnum } from 'store/storeTypes';
import { config } from '../config';

const configMobileProdAndroid: config = {
  AUTH_CONFIG_STR: {
    domain: 'kornit-konnect.eu.auth0.com',
    clientID: 'ML44BLej2btwkvRcwAEuUECteLb8zc2R',
    redirectUri: 'https://kornitkonnect.com',
    audience: 'https://kornitkonnect.com/api',
    returnTo: 'https://kornitkonnect.com',
    state:
      'hKFo2SB2LUJIM2N2TE1rc0lXdEhXWHZERXFXRXA4OUh3V3QyMKFupWxvZ2luo3RpZNkgeUFKa1V0cENNS1NrT1phbG1BVUZJbldYSUsyOHlWbjKjY2lk2SBNTDQ0QkxlajJidHdrdlJjd0FFdVVFQ3RlTGI4emMyUg',
    protocol: 'oauth2',
    response_type: 'token%20id_token',
    scope: 'openid%20profile%20user_metadata%20app_metadata',
    nonce: 'UULb-g89y3YMQxafdCMkOKQ131xti3Rz',
    auth0Client: 'eyJuYW1lIjoiYXV0aDAuanMiLCJ2ZXJzaW9uIjoiOS4xMC4wIn0%3D',
  },
  API_ROUTER_HOST: 'https://adminapi-prod.kornitkonnect.com/api/portalUrl',
  AUTH0_CLAIMS_NAMESPACE: 'https://konnect.kornit.com/',
  SELF_HOST: 'https://kornitkonnect.com',
  USER_LOGOUT_TIMEOUT: 14400000,
  USER_AGREEMENT_VERSION: 1.5,
  WEEK_START: 1,
  FILTER_HIDE: false,
  SHOW_PBI_REPORT_PAGES_PANE: false,
  SHOW_PBI_REPORT_FILTERS_PANE: false,
  REPORTS_REFRESH_TIMEOUT_SECONDS: 300,
  MAX_LOAD_ATTEMPTS: 5,
  SPLIT_WEEK_BY_END_OF_YEAR: true,
  ENV_NAME: ConfigNamesEnum.Production,
  PLATFORM: 'android',
  SHOW_VERSION_VERSION_LABEL: false,
  GENERATE_SOURCEMAP: false,
  LOGGER_INSTRUMENTAION_KEY: '8c2aafbd-94d4-4ea3-a820-cd582964ea05',
  REFRESH_API_INTERVAL: 600000,
  MIXPANEL_KEY: '20cbcec35d502ba3f1451070c160353d',
  UNSUBSCRIBE_END_POINT:
    'https://portalapi-prod.kornitkonnect.com/api/email-reports-scheduler/unsubscribe',
  SERVICE_TICKET_END_POINT: 'https://Kornit.formtitan.com/CaseFormJuly',
  BLOB_URL: 'https://prodedge1portaldata.blob.core.windows.net',
  API_URL: 'https://portalapi-prod.kornitkonnect.com',
  RECAPTCHA_KEY: '6LdzndIjAAAAANQ7LVjhg7siwpSWmVM3A9YkXai8',
  XMR_CHRART_FEATURE_ALLOWED_TENNANT_IDS: ['0010N00004do1W8QAI'],
};
export default configMobileProdAndroid;
