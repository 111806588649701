import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import env from '../../config/config';

import {
  jobsReportPath,
  overviewReportPath,
  systemsReportPath,
  tvDashboardPath,
} from 'contracts/constants';

import DesktopShellReportsNav from './DesktopShellReportsNav';
import MobileShellReportsNav from './MobileShellReportsNav';
import { ReportTypesNew } from 'models/ReportData';

export type ShellReportsNavProps = {
  onNavClick?: () => {};
  userEmail?: string;
  tennantName?: string;
  importedTennantId?: string;
  isMobile?: boolean;
  activeReport?: ReportTypesNew;
  setIsOpen?: (isOpen: boolean) => void;
  setSystemGroup: (systemGroup: string) => void;
};
export type NavlinkType = {
  text: string;
  path: string;
  image: string;
};
const ShellReportsNav = memo((props: ShellReportsNavProps) => {
  const { isMobile, activeReport, importedTennantId, setIsOpen, setSystemGroup } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const currentReport = history.location.pathname;

  //*allowed only for single client
  const xmrAllowedIds = env.XMR_CHRART_FEATURE_ALLOWED_TENNANT_IDS;

  const isTvDashboardAllowed = xmrAllowedIds?.includes(importedTennantId);
  const navLinks: Array<NavlinkType> = [
    {
      text: t('reportsNav.overview'),
      path: overviewReportPath,
      image: 'menu-1-overview.svg',
    },
    {
      text: t('reportsNav.systemsAnalysis'),
      path: systemsReportPath,
      image: 'menu-2-sys-analysis.svg',
    },
    {
      text: t('reportsNav.jobsAnalysis'),
      path: jobsReportPath,
      image: 'menu-3-jobs-analysis.svg',
    },
  ];

  isTvDashboardAllowed &&
    navLinks.push({
      text: t('reportsNav.tvDashboard'),
      path: tvDashboardPath,
      image: 'menu-4-tv-dashboard.svg',
    });
  return isMobile ? (
    <MobileShellReportsNav
      activeReport={activeReport}
      currentReport={currentReport}
      navLinks={navLinks}
      setIsOpen={setIsOpen}
    />
  ) : (
    <DesktopShellReportsNav
      currentReport={currentReport}
      navLinks={navLinks}
      setSystemGroup={setSystemGroup}
    />
  );
});

export default ShellReportsNav;
