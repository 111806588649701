import React from 'react';
import styled, { keyframes } from 'styled-components';
import FilterSearcherContainer from 'components/FilterSystemsPopUpMenu/FilterSearcherContainer';
import FilterSystemsPopUpMenu from 'components/FilterSystemsPopUpMenu';
import { appearanceEffect } from 'globalStyle';

const FilterSlider = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  width: 230px;
  height: 100%;
  padding-bottom: 40px;
  box-sizing: border-box;
  display: flex;
  background: #ffffff;
  border: none;
  box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.16);
  animation-name: ${appearanceEffect};
  animation-duration: 500ms;
  z-index: 1;
`;

const FilterSystemsPopUpMenuWrapper = styled.div<{ pbiReportHeight: string }>`
  overflow: auto;
  height: ${props => props.pbiReportHeight};
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  padding-bottom: 80px;

  scrollbar-width: thin;
  scrollbar-color: #c1baba;

  &::-webkit-scrollbar {
    width: 9px;
  }
  &::-webkit-scrollbar-track {
    border-left: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: #c5d0de;
    border-radius: 13px;
  }

  @media only screen and (max-width: 1280px) {
    height: 600px;
  }
`;

const ControlWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  position: sticky;
  top: 0;
  z-index: 100;
  background: white;
`;
const SearchingBar = styled.div`
  width: 200px;
  height: 100%;
  caret-color: black;
`;

const Slider = props => {
  const { screenWidth, pbiReportHeight } = props;

  return (
    <>
      {/* mobile version is in PbiFilterSystems component */}
      {screenWidth > 1400 && (
        <FilterSlider data-id={'FilterSlider'}>
          <FilterSystemsPopUpMenuWrapper
            data-id={'FilterSystemsPopUpMenuWrapper'}
            pbiReportHeight={`${pbiReportHeight}px`}>
            <ControlWrapper>
              <SearchingBar>
                <FilterSearcherContainer />
              </SearchingBar>
            </ControlWrapper>
            <FilterSystemsPopUpMenu pbiReportHeight={pbiReportHeight} isMobile={false} />
          </FilterSystemsPopUpMenuWrapper>
        </FilterSlider>
      )}
    </>
  );
};
export default Slider;
