import React from 'react';
import { ReactComponent as CheckedIcon } from '../../assets/icons/CheckedIcon.svg';
import { ReactComponent as HalfCheckedIcon } from '../../assets/icons/HalfCheckedIcon.svg';
import { RowWrapper, LeftPart, CheckBox, NameWrapper, RightPart } from './SiteRow';
import { StyledTooltip } from 'globalStyle';
import { useTranslation } from 'react-i18next';

export type AllBtnProps = {
  totalSystemCount: number;
  selectedSystemsCount: number;
  selectedSystemGroup: string;
  filterData: any;
  filterState: any;
  isSignleSelectionMode: boolean;
  toggleAllSystemsSelection: ({}) => void;
};
const AllBtn = (props: AllBtnProps) => {
  const {
    totalSystemCount,
    selectedSystemsCount,
    selectedSystemGroup,
    filterState,
    filterData,
    isSignleSelectionMode,
    toggleAllSystemsSelection,
  } = props;
  const { t } = useTranslation();
  const getCheckedIcon = (totalCount: number, selectedCount: number) => {
    if (totalCount === selectedCount) {
      return <CheckedIcon fill="white" />;
    }
    if (selectedCount > 0 && selectedCount < totalCount) {
      return <HalfCheckedIcon fill="white" style={{ marginTop: '1px' }} />;
    }
  };
  const CheckedIconJSX = getCheckedIcon(totalSystemCount, selectedSystemsCount);
  const clickHandler = () => {
    if (!isSignleSelectionMode) {
      toggleAllSystemsSelection({ selectedSystemGroup, filterData, filterState });
    }
  };
  return (
    <>
      <StyledTooltip
        disableHoverListener={!isSignleSelectionMode}
        title={t('tooltip.xmr.singleSelection')}>
        <RowWrapper style={{ marginTop: '10px' }} data-automationid={`filter_systems_selectAllBtn`}>
          <LeftPart onClick={clickHandler}>
            <CheckBox checked={selectedSystemsCount > 0}>
              {selectedSystemsCount > 0 && CheckedIconJSX}
            </CheckBox>

            <NameWrapper>{'All'}</NameWrapper>
          </LeftPart>
          <RightPart></RightPart>
        </RowWrapper>
      </StyledTooltip>
    </>
  );
};
export default AllBtn;
