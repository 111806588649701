import { UserSystemGroupStrings } from 'store/storeTypes';
import { createAsyncAction } from 'typesafe-actions';
import { makeApiUrl } from './_shared/utils';
import { authService } from 'services/AuthProvider';
import apiAction from './_shared/apiAction';
import { getRealTimeHeaders } from './_shared/realTimeHeaders';
import { convertServerResponseToChartData } from 'helpers/tv-dashboard-utils';

export interface TvDashobardRequest {
  system: string;
  systemGroup: UserSystemGroupStrings;
  isSilent?: boolean;
  isRealTime?: boolean;
}
export const getTvDashboardDataAC = createAsyncAction(
  'GET_TV_DASHBOARD_DATA_STARTED',
  'GET_TV_DASHBOARD_DATA_SUCCESS',
  'GET_TV_DASHBOARD_DATA_FAILED'
)<TvDashobardRequest, any, Error>();

export const getTvDashboardDataRequest = ({
  systemGroup,
  system,
  isSilent = false,
  isRealTime = false,
}: TvDashobardRequest) => {
  const url = makeApiUrl('/api/tv-dashboard/xmrImpressions');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub) {
    return apiAction({
      request: {
        url,
        method: 'GET',
        headers: getRealTimeHeaders(isRealTime),

        params: {
          systemGroup,
          system,
        },
      },
      logic: {
        onFailed: (error, dispatch) => dispatch(getTvDashboardDataAC.failure(error)),
        onStarted: dispatch =>
          dispatch(getTvDashboardDataAC.request({ systemGroup, system, isSilent })),
        onSuccess: (data, dispatch) => {
          const {
            currentShiftType,
            impRatio,
            shiftImp,
            shiftImpTarget,
            xmrImpressionsResponseData,
            currentShiftStartDate,
          } = data;
          const records = convertServerResponseToChartData(xmrImpressionsResponseData);
          dispatch(
            getTvDashboardDataAC.success({
              systemGroup,
              currentShiftStartTime: currentShiftStartDate,
              records,
              currentShiftType,
              totalImpressions: shiftImp,
              targetImpressions: shiftImpTarget,
              percentage: impRatio,
            })
          );
        },
      },
    });
  }
};
