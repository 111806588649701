import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { createAsyncAction, createStandardAction as createAction } from 'typesafe-actions';
import { TreeNodeProps } from 'react-dropdown-tree-select';

import {
  Store,
  ReportFilterData,
  ReportSite,
  ReportModel,
  ReportSystem,
  UserSystemGroupStrings,
  TimeModificator,
} from 'store/storeTypes';
import { ReportTypesNew, TimeFrame } from 'models/ReportData';
import { makeApiUrl } from './_shared/utils';
import { authService } from 'services/AuthProvider';
import apiAction from './_shared/apiAction';
import { addWeeks, startOfWeek } from 'date-fns';
import { KonnectAnalytics } from 'KonnectAnalytics/konnectAnalytics';

export const getEmptyStateAC = createAsyncAction(
  'GET_EMPTY_STATE_STARTED',
  'GET_EMPTY_STATE_SUCCESS',
  'GET_EMPTY_STATE_FAILED'
)<any, any, Error>();

export const getEmptyState = () => {
  const url = makeApiUrl('/api/reports/reported-printers-status');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub) {
    return apiAction({
      request: {
        url,
      },
      logic: {
        onFailed: (error, dispatch) => {
          dispatch(getEmptyStateAC.failure(error));
        },
        onStarted: dispatch => {
          dispatch(getEmptyStateAC.request({}));
        },
        onSuccess: (data, dispatch) => {
          dispatch(getEmptyStateAC.success(data));
        },
      },
    });
  }
};

interface SetInitFilterStatePayload {
  selectedSystemGroup: UserSystemGroupStrings;
  filterData: ReportFilterData;
}
export const setInitFilterStateAC = createAction('SET_INITIAL_FILTER_STATE')<
  SetInitFilterStatePayload
>();
export const setInitFilterState = () => (
  dispatch: ThunkDispatch<Store, any, Action>,
  getState: () => Store
) => {
  const { filterData } = getState().reports;
  const { selectedSystemGroup } = getState().ui;
  dispatch(
    setInitFilterStateAC({
      selectedSystemGroup,
      filterData: filterData[selectedSystemGroup]!,
    })
  );
};

export interface SetFilterFromUriQueryPayload {
  filterData: ReportFilterData;
  timeframe: TimeFrame;
  selectedDate: string | null;
  selectedSiteIds: string | null;
  selectedModelIds: string | null;
  selectedSystemSerials: string | null;
  selectedSystemGroup: UserSystemGroupStrings;
}
export type SetFilterFromUriQuery = Omit<SetFilterFromUriQueryPayload, 'filterData'>;

export const setFilterFromUriQueryAC = createAction('SET_FILTER_FROM_URI_QUERY')<
  SetFilterFromUriQueryPayload
>();
export const setFilterFromUriQuery = (params: SetFilterFromUriQuery) => (
  dispatch: ThunkDispatch<Store, any, Action>,
  getState: () => Store
) => {
  const { filterData, filterState } = getState().reports;
  const selectedSystemGroup = params?.selectedSystemGroup;
  dispatch(
    setFilterFromUriQueryAC({
      filterData: filterData[selectedSystemGroup]!,
      selectedSiteIds: filterState[selectedSystemGroup]?.selectedSiteIds,
      selectedModelIds: filterState[selectedSystemGroup]?.selectedModelIds,
      selectedSystemSerials: filterState[selectedSystemGroup]?.selectedSystemSerials,
      ...params,
    })
  );
};
export type CustomTimeFrameProperties = {
  customTimeFrameStartDate: Date | null;
  customTimeFrameEndDate: Date | null;
  customTimeFrameStartHour?: number | null;
  customTimeFrameEndHour?: number | null;
  customEndTimeModificator?: TimeModificator;
  customStartTimeModificator?: TimeModificator;
};
interface SetTimeframePayload {
  selectedSystemGroup: UserSystemGroupStrings;
  timeframe: TimeFrame;
  startDate?: Date;
  activeReport?: ReportTypesNew;
  customTimeFrameProperties?: CustomTimeFrameProperties;
}
export const setCustomTimeFrameHoursToDefaultValue = createAction(
  'SET_CUSTOM_TIMEFRAME_HOURS_TO_DEFAULTT_VALUE'
)<{ selectedSystemGroup: UserSystemGroupStrings }>();
export const setTimeframeAC = createAction('SET_TIMEFRAME')<SetTimeframePayload>();

export const setTimeframe = (
  timeframe: TimeFrame,
  startDate?: Date,
  customTimeFrameProperties?: CustomTimeFrameProperties
) => (dispatch: ThunkDispatch<Store, any, Action>, getState: () => Store) => {
  const activeReport = getState().reports?.activeReport;
  const { selectedSystemGroup } = getState().ui;

  KonnectAnalytics.changeTimeFrameFilter('changeTimeFrame', {
    nextTimeFilter: timeframe,
    startDate: customTimeFrameProperties?.customTimeFrameStartDate.toDateString(),
    endDate: customTimeFrameProperties?.customTimeFrameEndDate.toDateString(),
    customStartHour: customTimeFrameProperties?.customTimeFrameStartHour,
    customEndHour: customTimeFrameProperties?.customTimeFrameEndHour,
  });
  dispatch(
    setTimeframeAC({
      selectedSystemGroup,
      timeframe,
      startDate,
      activeReport,
      customTimeFrameProperties,
    })
  );
};

interface SetActiveDatePayload {
  selectedSystemGroup: UserSystemGroupStrings;
  timeframe: TimeFrame;
  date: Date;
}

export const setActiveDateAC = createAction('SET_ACTIVE_DATE')<SetActiveDatePayload>();
export function setActiveDate(date: Date) {
  return async function(dispatch: ThunkDispatch<Store, any, Action>, getState: () => Store) {
    const { selectedSystemGroup } = getState().ui;
    const startDate = getState().reports.filterData[selectedSystemGroup].startDate;
    const timeframe = getState().reports.filterState[selectedSystemGroup]!.timeframe;
    const isStartDateMoreThanIncomeDate = startDate > date;
    if (isStartDateMoreThanIncomeDate) {
      const checkedObkjectWithProperDate = {
        date: startDate,
        timeframe,
        selectedSystemGroup,
      };
      dispatch(setActiveDateAC(checkedObkjectWithProperDate));
    }
    //* if that is in week timeframe we always set selected day on the  Monday
    if (timeframe === 'week') {
      //*get first day(Monday) of current week - just to keep selectedDate
      //* in state.reports.filterState[selectedSystemGroup].selectedDate;
      //*in the beggining of  this week
      const firstDayInWeek = startOfWeek(date, { weekStartsOn: 1 });
      dispatch(setActiveDateAC({ date: firstDayInWeek, timeframe, selectedSystemGroup }));
    } else {
      dispatch(setActiveDateAC({ date, timeframe, selectedSystemGroup }));
    }
  };
}
export const setNodeFoldedStateAC = createAction('SET_NODE_FOLDED_STATE')<{
  node: TreeNodeProps;
  selectedSystemGroup: UserSystemGroupStrings;
}>();

export const setNodeFoldedState = (node: TreeNodeProps) => (
  dispatch: ThunkDispatch<Store, any, Action>,
  getState: () => Store
) => {
  const { selectedSystemGroup } = getState().ui;
  dispatch(
    setNodeFoldedStateAC({
      selectedSystemGroup,
      node,
    })
  );
};

interface SetFilterStatePayload {
  selectedSystemGroup: UserSystemGroupStrings;
  selectedNodes: TreeNodeProps[];
  currentNode?: TreeNodeProps;
  sites: ReportSite[];
  models: ReportModel[];
  systems: ReportSystem[];
}

interface ResetFilterStatePayload {
  selectedSystemGroup: UserSystemGroupStrings;
  sites: ReportSite[];
  models: ReportModel[];
  systems: ReportSystem[];
}

export const setFilterSystemsStateAC = createAction('SET_FILTER_SYSTEMS_STATE')<
  SetFilterStatePayload
>();
export function setFilterSystemsState(selectedNodes: TreeNodeProps[], currentNode?: TreeNodeProps) {
  return async function(dispatch: ThunkDispatch<Store, any, Action>, getState: () => Store) {
    const { selectedSystemGroup } = getState().ui;
    const { sites, models, systems } = getState().reports.filterData[selectedSystemGroup]!;
    dispatch(
      setFilterSystemsStateAC({
        selectedSystemGroup,
        selectedNodes,
        currentNode,
        sites,
        models,
        systems,
      })
    );
  };
}

export const resetFilterSystemsStateAC = createAction('RESET_FILTER_SYSTEMS_STATE')<
  ResetFilterStatePayload
>();
export function resetFilterSystemsState() {
  return async function(dispatch: ThunkDispatch<Store, any, Action>, getState: () => Store) {
    const { selectedSystemGroup } = getState().ui;
    const { sites, models, systems } = getState().reports.filterData[selectedSystemGroup]!;
    dispatch(resetFilterSystemsStateAC({ selectedSystemGroup, sites, models, systems }));
  };
}

interface JumpToLatestPeriodPayload {
  selectedSystemGroup: UserSystemGroupStrings;
  filterData: ReportFilterData;
}

export const jumpToLatestPeriodAC = createAction('JUMP_TO_LATEST_PERIOD')<
  JumpToLatestPeriodPayload
>();
export const jumpToLatestPeriod = () => (
  dispatch: ThunkDispatch<Store, any, Action>,
  getState: () => Store
) => {
  const { filterData } = getState().reports;
  const { selectedSystemGroup } = getState().ui;
  dispatch(
    jumpToLatestPeriodAC({
      selectedSystemGroup,
      filterData: filterData[selectedSystemGroup]!,
    })
  );
};

export const hidePbiFilterAC = createAction('HIDE_PBI_FILTER')();
export const revealPbiFilterAC = createAction('REVEAL_PBI_FILTER')();

interface ApplyMobileFiltersPayload {
  isApplyMobileFilter: boolean;
  selectedSystemGroup: UserSystemGroupStrings;
}

export const applyMobileFiltersAC = createAction('APPLY_MOBILE_FILTERS')<
  ApplyMobileFiltersPayload
>();
export const applyMobileFilters = (isApplyMobileFilter: boolean) => (
  dispatch: ThunkDispatch<Store, any, Action>,
  getState: () => Store
) => {
  const { selectedSystemGroup } = getState().ui;
  dispatch(
    applyMobileFiltersAC({
      selectedSystemGroup,
      isApplyMobileFilter,
    })
  );
};

export const getDefaultFilterQueries = createAction('GET_DEFAULT_FILTER_QUERIES')<
  SetFilterFromUriQuery
>();

interface SetSelectedSystemIdsPayload {
  selectedSystemSerials: Array<string>;
  selectedSystemGroup: UserSystemGroupStrings;
}

export const setSelectedSystemIds = (selectedSystemSerials: Array<string>) => (
  dispatch: ThunkDispatch<Store, any, Action>,
  getState: () => Store
) => {
  const { selectedSystemGroup } = getState().ui;
  dispatch(
    setSelectedSystemIdsAC({
      selectedSystemGroup,
      selectedSystemSerials,
    })
  );
};

export const setSelectedSystemIdsAC = createAction('SET_SELECTED_SYSTEM_IDS')<
  SetSelectedSystemIdsPayload
>();

export const openFilterPopUpMenu = createAction('OPEN_FILTER_POPUP_MENU')<{
  selectedSystemGroup: string;
}>();
export const toggleFilterRowSystemsSelection = createAction('SELECT_SYSTEMS_BY_SITE')<{
  selectedSystemGroup: string;
  siteId: string;
  filterData: any;
  filterState: any;
}>();
export const toggleFilterModelRowSystemsSelection = createAction('SELECT_SYSTEMS_BY_MODEL')<{
  selectedSystemGroup: string;
  modelId: string;
  siteId: string;
  filterData: any;
  filterState: any;
}>();
export const toggleFilterSerialRowSystemSelection = createAction('SELECT_SYSTEMS_BY_SERIAL')<{
  selectedSystemGroup: string;
  modelId: string;
  siteId: string;
  serialId: string;
  filterData: any;
  filterState: any;
}>();
export const toggleAllSystemsSelection = createAction('TOGGLE_ALL_SYSTEMS_SELECTION')<{
  selectedSystemGroup: string;
  // filterData?: any;
  // filterState?: any;
}>();
export type SinglePrinterSelectionPayload = {
  selectedSystemGroup: string;
  modelId: string;
  siteId: string;
  serialId: string;
};
export const singlePrinterSelection = createAction('SINGLE_PRINTER_SELECTION')<
  SinglePrinterSelectionPayload
>();
export const setFilterValue = createAction('SET_FILTER_VALUE')<{
  value: string;
  selectedSystemGroup: string;
}>();
export const unselectSerialsByModel = createAction('UNSELECT_SERIAL_BY_MODEL')<{
  selectedSystemGroup: string;
  modelId: string;
  filterData: ReportFilterData;
}>();
