import React, { memo } from 'react';
import { ShellHeaderProps } from './ShellHeaderContainer';
import { UserSystemGroup, UserSystemGroupStrings } from 'store/storeTypes';
import ShellHeaderDesktop from './ShellHeaderDesktop';
import ShellHeaderMobile from './ShellHeaderMobile';
import { ReportTypesNew } from 'models/ReportData';
export type ShellHeaderUiProps = {
  activeSystemsMap: string[];
  checkedLastUpdate: Date;
  profilePictureUrl: string;
  selectedSystemGroup: any;
  activeReport: ReportTypesNew;
  userEmail: string;
  tennantName: string;
  isReadOnlyRole: boolean;
  lastResponseUpdateTime: Date;
  importedTennantId?: string;
  setSystemGroup: (systemGroup: UserSystemGroupStrings) => void;
};
const ShellHeader = memo((props: ShellHeaderProps) => {
  const {
    profilePictureUrl,
    activeSystems,
    lastUpdateDTF,
    lastUpdateDTG,
    lastUpdateApollo,
    selectedSystemGroup,
    screenWidth,
    activeReport,
    userEmail,
    tennantName,
    isReadOnlyRole,
    lastResponseUpdateTime,
    importedTennantId,
    setSystemGroup,
  } = props;
  const lastUpdate =
    selectedSystemGroup === UserSystemGroup.DTG
      ? lastUpdateDTG
      : selectedSystemGroup === UserSystemGroup.Apollo
      ? lastUpdateApollo
      : lastUpdateDTF;
  const checkedLastUpdate = new Date(lastUpdate);

  const activeSystemsMap = activeSystems && activeSystems.split(' ');
  const isMobileView = screenWidth <= 900;
  return (
    <>
      {isMobileView ? (
        <ShellHeaderMobile
          userEmail={userEmail}
          profilePictureUrl={profilePictureUrl}
          selectedSystemGroup={selectedSystemGroup}
          activeSystemsMap={activeSystemsMap === null ? ['0', '/', '0'] : activeSystemsMap}
          checkedLastUpdate={checkedLastUpdate}
          activeReport={activeReport}
          tennantName={tennantName}
          isReadOnlyRole={isReadOnlyRole}
          lastResponseUpdateTime={lastResponseUpdateTime}
          setSystemGroup={setSystemGroup}
        />
      ) : (
        <ShellHeaderDesktop
          userEmail={userEmail}
          profilePictureUrl={profilePictureUrl}
          selectedSystemGroup={selectedSystemGroup}
          activeSystemsMap={activeSystemsMap === null ? ['0', '/', '0'] : activeSystemsMap}
          checkedLastUpdate={checkedLastUpdate}
          activeReport={activeReport}
          tennantName={tennantName}
          isReadOnlyRole={isReadOnlyRole}
          lastResponseUpdateTime={lastResponseUpdateTime}
          importedTennantId={importedTennantId}
          setSystemGroup={setSystemGroup}
        />
      )}
    </>
  );
});

export default ShellHeader;
